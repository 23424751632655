<mat-menu #menu="matMenu" yPosition="above" class="feedback-dropdown">
  <waitlyst-widget
    class="waitlyst-widget"
    (click)="$event.stopPropagation()"
    product-name="Contingent"
    [attr.publishable-key]="publishableKey"
  ></waitlyst-widget>
</mat-menu>

<button type="button" class="feedback-button" [matMenuTriggerFor]="menu">
  <svg xmlns="http://www.w3.org/2000/svg" width="15.285" height="16.893" viewBox="0 0 15.285 16.893">
    <g id="speaker" transform="translate(-21.309 0)" opacity="0.913">
      <g id="Group_3377" data-name="Group 3377" transform="translate(21.309 0)">
        <path
          id="Path_4220"
          data-name="Path 4220"
          d="M34.652.374,31.018,3.68a1.631,1.631,0,0,1-.856.333H24.526a3.219,3.219,0,0,0,0,6.439V14.48a2.414,2.414,0,0,0,2.413,2.413h.805a1.607,1.607,0,0,0,1.608-1.609v-3.22H27.745v3.22h-.8a.805.805,0,0,1-.8-.8V10.451a1.608,1.608,0,0,0-1.608-1.607,1.61,1.61,0,0,1,0-3.221h5.636A3.188,3.188,0,0,0,32.1,4.871l2.884-2.624V12.23L32.1,9.594a3.184,3.184,0,0,0-1.938-.75h-.805V7.233H27.745v3.219h2.414a1.63,1.63,0,0,1,.853.33l3.643,3.325a1.108,1.108,0,0,0,1.939-.856V1.228A1.11,1.11,0,0,0,34.652.374Z"
          transform="translate(-21.309 0)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
  FEEDBACK ?
</button>
