import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private message = new Subject<any>();
  sendMessage(messages: Array<string>, type = 1, style = 1) {
    this.message.next({ text: messages, type, style });
  }
  getMessage(): Observable<any> {
    return this.message.asObservable();
  }
  clearMessage() {
    this.message.complete();
  }
}
