import { Inject, NgModule } from '@angular/core';
import { SvgIconRegistry, SvgIconsModule } from '@ngneat/svg-icon';
import * as icons from './assets';

@NgModule({
  imports: [SvgIconsModule.forRoot()],
  exports: [SvgIconsModule],
  providers: [],
})
export class SharedSvgIconsModule {
  constructor(@Inject(SvgIconRegistry) registry: SvgIconRegistry) {
    const svgIcons = Object.entries(icons).map(([_, svgIcon]) => svgIcon);

    registry.register([...svgIcons]);
  }
}
