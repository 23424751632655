import { gql } from 'graphql-request';

export const OFFICERS = gql`
  query Officers($id: String!) {
    officers(id: $id) {
      officers {
        id
        name
        role
        dateOfBirth {
          month
          year
        }
        nationalityCountryCodes
        address {
          countryCode
          city
        }
        isActive
      }
    }
  }
`;

export const APPOINT_OFFICER = gql`
  mutation AppointOfficer($id: String!, $officer: AppointOfficerInput!) {
    appointOfficer(id: $id, officer: $officer) {
      name
    }
  }
`;

export const UPDATE_OFFICER = gql`
  mutation UpdateOfficer($id: String!, $officer: UpdateOfficerInput!) {
    updateOfficer(id: $id, officer: $officer) {
      name
    }
  }
`;

export const TERMINATE_OFFICER = gql`
  mutation TerminateOfficer($businessId: String!, $officerId: String!) {
    terminateOfficer(businessId: $businessId, officerId: $officerId) {
      id
    }
  }
`;
