import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Address,
  BusinessRegistrationAuthority,
  CountryCode,
  EntityStatus,
  Evidence,
  LegalEntityFormType,
} from '@contingenton/types';
import { DiscoveredEntitiesApiService } from '@workbench-root/core/services/apis';
import { AddressFields } from '@workbench-root/forms/address-form/address-form.component';
import { countries, ErrorHandler, Jurisdiction } from '@workbench-root/types';
import { AddBusinessCommand } from '@workbench-root/types/business';
import registrationAuthoritiesJson from '@workbench-root/types/registrationAuthorities.json';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

const { registrationAuthorities } = registrationAuthoritiesJson;

enum CompanyFields {
  Jurisdiction = 'jurisdiction',
  RegistrationId = 'registrationId',
  IncorporationDate = 'incorporationDate',
  RegistrationAuthority = 'registrationAuthority',
  Status = 'status',
  LegalEntityForm = 'legalEntityForm',
  CompanyName = 'name',
  Address = 'address',
  Evidence = 'evidence',
}

export const LEGAL_ENTITY_OPTIONS = [
  LegalEntityFormType.Individual,
  LegalEntityFormType.Corporation,
  LegalEntityFormType.Charity,
  LegalEntityFormType.NonProfitOrganisation,
  LegalEntityFormType.Trust,
  LegalEntityFormType.Foundation,
  LegalEntityFormType.OverseasCompany,
  LegalEntityFormType.LimitedLiabilityPartnership,
  LegalEntityFormType.LimitedLiabilityCompany,
  LegalEntityFormType.Bank,
  LegalEntityFormType.FinancialInstitution,
  LegalEntityFormType.ListedCompany,
  LegalEntityFormType.StateOwned,
  LegalEntityFormType.RegulatedInstitution,
  LegalEntityFormType.PooledInvestmentVehicle,
  LegalEntityFormType.Fund,
  LegalEntityFormType.VentureCapital,
  LegalEntityFormType.PrivateCompany,
  LegalEntityFormType.PrivateLimitedCompany,
  LegalEntityFormType.PublicLimitedCompany,
  LegalEntityFormType.GeneralPartnership,
  LegalEntityFormType.LimitedPartnership,
  LegalEntityFormType.GeneralLiabilityCorporation,
  LegalEntityFormType.SoleProprietorship,
  LegalEntityFormType.SimplifiedJointStockCompany,
  LegalEntityFormType.SimplifiedJointStockCompanyWithSinglePartner,
];

declare interface FormValueType {
  name: string;
  jurisdiction: CountryCode;
  registrationId: string;
  incorporationDate: Date;
  registrationAuthority: BusinessRegistrationAuthority;
  status: EntityStatus;
  legalEntityForm: LegalEntityFormType;
  address: Address;
  evidence: Evidence;
}

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss'],
})
export class NewCompanyComponent extends ErrorHandler implements OnInit, OnDestroy {
  jurisdictions: Jurisdiction[] = countries;
  regAuthorities = registrationAuthorities;
  fields = CompanyFields;
  submitting = false;

  companyForm = this.fb.group({
    status: ['Active', Validators.required],
    legalEntityForm: ['', Validators.required],
    jurisdiction: ['', Validators.required],
    registrationId: ['', Validators.required],
    incorporationDate: ['', Validators.required],
    registrationAuthority: [{ value: '', disabled: true }, [Validators.required]],
    name: ['', Validators.required],
    address: this.fb.group(AddressFields),
    evidence: [],
  });
  searchComplete = false;
  submitAttempted = false;
  statusOptions = [EntityStatus.Active, EntityStatus.Inactive, EntityStatus.Intermediate];
  legalEntityForms: Array<LegalEntityFormType> = LEGAL_ENTITY_OPTIONS;

  get showErrors() {
    return this.companyForm.invalid;
  }

  get postData(): AddBusinessCommand {
    const formValue: FormValueType = this.companyForm.value;

    return {
      name: formValue.name,
      jurisdiction: formValue.jurisdiction,
      legalAddress: formValue.address,
      legalEntityForm: formValue.legalEntityForm,
      status: formValue.status,
      registrationAuthority: {
        id: formValue.registrationId,
        registrationAuthorityCode: formValue.registrationAuthority.registrationAuthorityCode,
        registrationDate: formValue.incorporationDate,
        lastUpdated: new Date(),
      },
      evidence: {
        ...formValue.evidence,
        retrievedAt: new Date(),
      },
    };
  }

  destroy$ = new Subject<boolean>();

  constructor(
    public fb: FormBuilder,
    public discoveredEntities: DiscoveredEntitiesApiService,
    public toast: MatSnackBar,
    public router: Router,
  ) {
    super(toast);
  }

  field(fieldName: CompanyFields): AbstractControl {
    return this.companyForm.get(fieldName);
  }

  async submit() {
    if (this.companyForm.valid) {
      this.submitting = true;
      try {
        await this.discoveredEntities.addBusiness(this.postData);
        this.submitting = false;
        this.toast.open('Company has been created successfully', null, { duration: 4000 });
        return this.router.navigate(['/']);
      } catch (e) {
        console.error(e);
        this.onError(e, this.submit);
        this.submitting = false;
      }
    } else {
      this.toast.open('Please complete the form before submitting', null, { duration: 4000 });
    }
  }

  search() {
    this.submitAttempted = true;
    this.searchComplete = true;
  }

  ngOnInit() {
    this.companyForm
      .get('jurisdiction')
      .valueChanges.pipe(
        tap((countryCode) => {
          this.regAuthorities = registrationAuthorities.filter(
            (regAuthority) => regAuthority.countryCode === countryCode,
          );
          this.companyForm.get('registrationAuthority').enable();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
