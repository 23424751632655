import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'contingent-ui-search-card-icon, [contingentUiSearchCardIcon]',
})
export class ContingentSearchIcon {
  @HostBinding('class.contingent-ui-search-card-icon') ui = true;
}

@Directive({
  selector: 'contingent-ui-search-card-results, [contingentUiSearchCardResults]',
})
export class ContingentSearchResults {
  @HostBinding('class.contingent-ui-search-card-results') searchIcon = true;
}

@Directive({
  selector: 'contingent-ui-search-card-result, [contingentUiSearchCardResult]',
})
export class ContingentSearchResult {
  @HostBinding('class.contingent-ui-search-card-result') searchIcon = true;
}

@Directive({
  selector: 'contingent-ui-search-card-input, [contingentUiSearchCardInput]',
})
export class ContingentSearchInput {
  @HostBinding('class.contingent-ui-search-card-input') ui = true;
}

@Directive({
  selector: 'contingent-ui-search-card-input-container, [contingentUiSearchCardInputContainer]',
})
export class ContingentSearchInputContainer {
  @HostBinding('class.contingent-ui-search-card-input-container') ui = true;
}
