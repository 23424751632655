import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class UncommittedEventsGQLService extends Query<UncommittedEventsResponse, UncommittedEventsRequest> {
  document = gql`
    query uncommittedEvents($businessId: String!) {
      uncommittedEvents(businessId: $businessId) {
        id
        type
        body
        sequenceNumber
        createdBy
        createdTimestamp
      }
    }
  `;
}

export interface UncommittedEventsRequest {
  businessId: string;
}

export interface UncommittedEvent {
  body: string;
  event: unknown;
  createdBy: string;
  createdTimestamp: Date;
  id: string;
  sequenceNumber: number;
  type: string;
}

export interface UncommittedEventsResponse {
  uncommittedEvents: Array<UncommittedEvent>;
}
