import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import * as JWT from 'jwt-decode';
import { from, Observable } from 'rxjs';
import { User } from './user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$: Observable<any>;
  refreshingToken = false;
  private readonly allowedDomains = ['contingenton.com', 'contingent.ai'];

  constructor(public afAuth: AngularFireAuth, private http: HttpClient, private router: Router) {
    this.user$ = this.afAuth.authState;
  }

  async logIn() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      hd: 'contingenton.com',
    });

    const credential = await this.afAuth.auth.signInWithPopup(provider);
    const idToken = await credential.user.getIdToken();

    const idTokenObject = JWT(idToken);

    if (idTokenObject.firebase.identities.email.every((email: string) => this.isEmailDomainApproved(email))) {
      this.updateUserData({
        uid: credential.user.uid,
        tid: credential.user.tenantId,
        email: credential.user.email,
        displayName: credential.user.displayName,
        photoURL: credential.user.photoURL,
        idToken,
      });
    }
  }

  async logInWithEmailAndPassword(email: string, password: string) {
    const credential = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    const idToken = await credential.user.getIdToken();

    this.updateUserData({
      uid: credential.user.uid,
      tid: credential.user.tenantId,
      email: credential.user.email,
      displayName: credential.user.displayName,
      photoURL: credential.user.photoURL,
      idToken,
    });
  }

  checkIfExpired() {
    const user = localStorage.getItem('user');
    if (user) {
      const exp = JWT(JSON.parse(user).idToken).exp;
      if (exp <= Date.now() / 1000) {
        return true;
      }
    }
    return false;
  }

  async logOut() {
    localStorage.removeItem('user');
    await this.afAuth.auth.signOut();
    return this.router.navigate(['/session/login']);
  }

  getIdToken(): Observable<string> {
    return from(this.refreshTokenHandle());
  }

  refreshTokenHandle(): Promise<any> {
    return new Promise((resolve, reject) =>
      firebase.auth().onIdTokenChanged(async (res: firebase.User) => {
        this.refreshingToken = false;
        if (res) {
          const idToken = await res.getIdToken();
          this.updateUserData({
            uid: res.uid,
            tid: res.tenantId,
            email: res.email,
            displayName: res.displayName,
            photoURL: res.photoURL,
            idToken,
          });
          resolve(idToken);
          return;
        } else {
          localStorage.removeItem('user');
          this.router.navigate(['/session/login']);
        }
        resolve(false);
      }),
    );
  }

  updateUserData(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  isAuthenticated() {
    const user = localStorage.getItem('user');
    if (user) {
      const exp = JWT(JSON.parse(user).idToken).exp;
      if (exp > Date.now() / 1000) {
        return true;
      }
    }
    return false;
  }

  private isEmailDomainApproved(email: string): boolean {
    const [, domain] = email.split('@');
    return this.allowedDomains.includes(domain);
  }
}
