import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackButtonComponent } from './feedback-button.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [FeedbackButtonComponent],
  exports: [FeedbackButtonComponent],
  imports: [CommonModule, MatMenuModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedUiFeedbackButtonModule {}
