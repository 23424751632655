import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  NewsArticleMutateResponse,
  NewsArticlesQueryResponse,
  NewsEntityMutateResponse,
  FetchNewsArticlesResponse,
  MatchStatus,
  NewsArticle,
  NewsEntity,
  FetchNewsEntitiesQueryResponse,
  NewsArticleInputType,
} from '../../../types/news';
import { Apollo, gql } from 'apollo-angular';

export const QUERY_ASSESSMENT_NEWS = gql`
  query assessment($id: String!) {
    assessment(id: $id) {
      newsEntities {
        id
        name
        allUris
        matchStatus
        importance
        source
        newsArticles {
          id
          title
          image
          summary
          siteName
          pageUrl
          matchingEntity
          matchStatus
          relevantTopics
          relevantDomains
          sentiment
          score
          publishedTimestamp
        }
      }
    }
  }
`;

export const FETCH_NEWS_ARTICLES = gql`
  mutation fetchNewsArticles($id: String!, $entities: [NewsEntitiesArticlesInputType!]!) {
    fetchNewsArticles(id: $id, entities: $entities) {
      id
      title
      siteName
      matchStatus
      summary
      pageUrl
      sentiment
      relevantTopics
      relevantDomains
    }
  }
`;
export const FETCH_NEWS_ENTITIES = gql`
  mutation fetchNewsEntities($id: String!, $entityName: String!, $jurisdictionCode: String!) {
    fetchNewsEntities(id: $id, entityName: $entityName, jurisdictionCode: $jurisdictionCode) {
      id
      name
      logo
      image
      description
      allUris
      matchStatus
      importance
    }
  }
`;

const UPDATE_NEWS_ENTITY_STATUS = gql`
  mutation updateNewsEntityStatus($businessId: String!, $entityId: String!, $matchStatus: MatchStatus!) {
    updateNewsEntityStatus(id: $businessId, entityStatus: { entityId: $entityId, matchStatus: $matchStatus }) {
      entityId
      name
      allUris
      matchStatus
    }
  }
`;

const CLASSIFY_NEWS_ARTICLE = gql`
  mutation classifyNewsArticle($businessId: String!, $classification: NewsClassificationInputType!) {
    classifyNewsArticle(id: $businessId, classification: $classification) {
      id
      title
      siteName
      summary
      pageUrl
      matchingEntity
      sentiment
      matchStatus
      relevantDomains
      relevantTopics
    }
  }
`;

const CREATE_NEWS_ARTICLE = gql`
  mutation addNewsManually($businessId: String!, $newsArticleInput: NewsArticleInputType!) {
    addNewsManually(id: $businessId, newsEntityId: $businessId, newsArticleInput: $newsArticleInput) {
      id
      title
      summary
      pageUrl
      siteName
      relevantTopics
      relevantDomains
      matchingEntity
      sentiment
      matchStatus
    }
  }
`;

@Injectable()
export class NewsApi {
  constructor(private apollo: Apollo) {}

  get(businessId: string): Observable<NewsEntity[]> {
    return this.apollo
      .watchQuery<NewsArticlesQueryResponse>({
        query: QUERY_ASSESSMENT_NEWS,
        variables: {
          id: businessId,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => {
          return response.data.assessment.newsEntities;
        }),
      );
  }

  fetchNewsEntities(id: string, entityName: string, jurisdictionCode: string): Observable<NewsEntity[]> {
    return this.apollo
      .mutate<FetchNewsEntitiesQueryResponse>({
        mutation: FETCH_NEWS_ENTITIES,
        variables: {
          id,
          entityName,
          jurisdictionCode,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response) => {
          return response.data.fetchNewsEntities.map((entity) => {
            return {
              ...entity,
              isTruePositive: entity.matchStatus === MatchStatus.TruePositive,
            };
          });
        }),
      );
  }

  updateNewsEntityStatus(businessId: string, newsEntityId: string, matchStatus: MatchStatus): Observable<NewsEntity> {
    return this.apollo
      .mutate<NewsEntityMutateResponse>({
        mutation: UPDATE_NEWS_ENTITY_STATUS,
        variables: {
          businessId,
          entityId: newsEntityId,
          matchStatus: MatchStatus[matchStatus],
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      })
      .pipe(map((response) => response.data.updateNewsEntityStatus));
  }

  classifyNewsArticle(
    businessId: string,
    newsEntityId: string,
    newsArticleId: string,
    matchStatus: MatchStatus,
    relevantTopics?: string[],
    matchingEntity?: string,
    newSentiment?: number,
  ): Observable<NewsArticle> {
    const variables = {
      businessId: businessId,
      classification: {
        entityId: newsEntityId,
        articleId: newsArticleId,
        matchStatus: matchStatus,
        relevantTopics: relevantTopics,
        matchingEntity: matchingEntity,
        sentiment: newSentiment,
      },
    };

    return this.apollo
      .mutate<NewsArticleMutateResponse>({
        mutation: CLASSIFY_NEWS_ARTICLE,
        variables: variables,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        refetchQueries: ['uncommittedEvents', 'assessment'],
      })
      .pipe(map((response) => response.data.updateNewsArticleStatus));
  }

  fetchNewsArticles(businessId: string, newsArticlesFetchRequest: { id: string; matchStatus: MatchStatus }[]) {
    return this.apollo
      .mutate<FetchNewsArticlesResponse>({
        mutation: FETCH_NEWS_ARTICLES,
        variables: {
          id: businessId,
          entities: newsArticlesFetchRequest,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((response) => response.data.fetchNewsArticles));
  }

  createNewsArticle(businessId: string, newsArticleInput: NewsArticleInputType) {
    const variables = {
      businessId: businessId,
      newsArticleInput: newsArticleInput,
    };

    return this.apollo
      .mutate<NewsArticleMutateResponse>({
        mutation: CREATE_NEWS_ARTICLE,
        variables,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        refetchQueries: ['uncommittedEvents', 'assessment'],
      })
      .pipe(map((response) => response.data.updateNewsArticleStatus));
  }
}
