import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { SharedUiFeedbackButtonModule } from '@contingent/shared/ui-feedback-button';
import { SharedUiNavModule } from '@contingent/shared/ui-nav';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MonthYearPickerComponent } from '@workbench-root/shared/month-year-picker/month-year-picker.component';
import { NgUploaderModule } from 'ng-uploader';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AuthGuard } from '../core/services/core/auth.guard';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { CountryFlagDirective } from './directives/country-flag/country-flag.directive';
import { ErrorDialogComponent } from './error-notification/error-dialog/error-dialog.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from './material.module';
import { AccordionDirective } from './menu-accordion/accordion.directive';
import { AccordionAnchorDirective } from './menu-accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './menu-accordion/accordionlink.directive';
import { MenuComponent } from './menu/menu.component';
import { NgSimpleDialogComponent } from './ng-simple-dialog/ng-simple-dialog.component';
import { NotificationComponent } from './notification/notification.component';
import { OptionsComponent } from './options/options.component';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { FormatMonthYearPipe } from './pipes/format-month-year.pipe';
import { RegistrationAuthorityPipe } from './pipes/registration-authority.pipe';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedUiSearchCardModule } from '@contingent/shared/ui-search-card';

@NgModule({
  declarations: [
    CountryFlagDirective,
    AdminLayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    MenuComponent,
    AccordionDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    NotificationComponent,
    OptionsComponent,
    SidebarComponent,
    ErrorDialogComponent,
    NgSimpleDialogComponent,
    CountryNamePipe,
    RegistrationAuthorityPipe,
    EllipsisPipe,
    FormatMonthYearPipe,
    MonthYearPickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LoadingBarRouterModule,
    PerfectScrollbarModule,
    TranslateModule,
    NgUploaderModule,
    FlexLayoutModule,
    MatTableModule,
    SharedUiNavModule,
    SharedUiFeedbackButtonModule,
    SharedUiSearchCardModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    NgxDatatableModule,
    LoadingBarRouterModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    MatTableModule,
    AdminLayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    MenuComponent,
    AccordionDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    CountryFlagDirective,
    NotificationComponent,
    OptionsComponent,
    SidebarComponent,
    ErrorDialogComponent,
    CountryNamePipe,
    EllipsisPipe,
    FormatMonthYearPipe,
    MonthYearPickerComponent,
    RegistrationAuthorityPipe,
  ],
  providers: [AuthGuard],
})
export class SharedModule {}
