import { gql } from 'graphql-request';

export const ADD_BUSINESS = gql`
  mutation AddBusiness($input: AddBusinessIdentityArgsType!) {
    addBusiness(businessIdentity: $input) {
      name
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation UpdateName($id: String!, $name: String!) {
    updateName(id: $id, name: $name) {
      name
    }
  }
`;

export const UPDATE_DESCRIPTION = gql`
  mutation updateDescription($id: String!, $description: UpdateDescriptionInputType!) {
    updateDescription(id: $id, description: $description) {
      description {
        value
      }
    }
  }
`;

export const UPDATE_TRANSLITERATED_NAME = gql`
  mutation UpdateTransliteratedName($id: String!, $name: String!) {
    updateTransliteratedName(id: $id, name: $name) {
      name
    }
  }
`;

export const UPDATE_CONTACT_INFORMATION = gql`
  mutation updateContactInformation($id: String!, $contactInformation: UpdateContactInformationInputType!) {
    updateContactInformation(id: $id, contactInformation: $contactInformation) {
      websiteUrls {
        value
      }
    }
  }
`;

export const DELETE_CONTACT_INFORMATION = gql`
  mutation deleteContactInformation($businessId: String!) {
    deleteContactInformation(businessId: $businessId) {
      status
    }
  }
`;

export const UPDATE_REGISTRATION_AUTHORITY = gql`
  mutation updateRegistrationInformation(
    $id: String!
    $registrationInformation: UpdateRegistrationInformationInputType!
  ) {
    updateRegistrationInformation(id: $id, registrationInformation: $registrationInformation) {
      registrationAuthority {
        value {
          id
        }
      }
    }
  }
`;

export const UPDATE_LEGAL_ADDRESS = gql`
  mutation updateLegalAddress($id: String!, $legalAddress: UpdateAddressInputType!) {
    updateLegalAddress(id: $id, legalAddress: $legalAddress) {
      description {
        value
      }
    }
  }
`;

export const UPDATE_HEADQUARTERS_ADDRESS = gql`
  mutation updateHeadquartersAddress($id: String!, $headquartersAddress: UpdateAddressInputType!) {
    updateHeadquartersAddress(id: $id, headquartersAddress: $headquartersAddress) {
      description {
        value
      }
    }
  }
`;

export const UPDATE_LEGAL_ENTITY = gql`
  mutation UpdateBusiness($id: String!, $business: UpdateBusinessIdentityInput!) {
    updateBusinessIdentity(id: $id, businessIdentity: $business) {
      status
    }
  }
`;

export const UPDATE_INDUSTRY_CLASSIFICATION = gql`
  mutation updateBusiness($id: String!, $business: UpdateBusinessIdentityInput!) {
    updateBusinessIdentity(id: $id, businessIdentity: $business) {
      industryClassificationCodes {
        code
        description
      }
    }
  }
`;

export const UPDATE_PREVIOUS_NAMES = gql`
  mutation updateBusiness($id: String!, $business: UpdateBusinessIdentityInput!) {
    updateBusinessIdentity(id: $id, businessIdentity: $business) {
      previousNames {
        name
      }
    }
  }
`;

export const UPDATE_TRADE_NAMES = gql`
  mutation updateBusiness($id: String!, $business: UpdateBusinessIdentityInput!) {
    updateBusinessIdentity(id: $id, businessIdentity: $business) {
      tradeNames
    }
  }
`;
