<div>
  <mat-card class="error-dialog">
    <mat-card-title>
      <b>{{ data.status }}</b>
    </mat-card-title>
    <mat-card-content style="overflow-y: auto;">
      <div fxLayout="row" fxLayoutAlign="space-around">
        <div class="section">
          <p>Please fix the following errors within the Spreadsheet and attempt to upload again.</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-around">
        <div class="section">
          <div *ngIf="data.reasons.length">
            <mat-list>
              <mat-list-item *ngFor="let value of data.reasons">
                <span style="display: block; word-wrap: break-word;" class="text-field">{{
                  value | slice: 0:100
                }}</span>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </mat-card-content>
    <div>
      <button mat-raised-button color="primary" (click)="close()">{{ 'OK' | translate }}</button>
    </div>
  </mat-card>
</div>
