<div fxLayout="row" fxLayoutAlign="space-around none">
  <mat-card fxFlex="70">
    <mat-card-header>
      <div class="header">
        <mat-icon>corporate_fare</mat-icon>
        <h3>Add new company</h3>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="companyForm" (submit)="submit()">
        <div fxLayout="row" fxLayoutAlign="space-between start" class="form-section">
          <div fxFlex="33">
            <h4>Registration Details</h4>
          </div>
          <div fxFlex="66">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-form-field fxFlex="50" appearance="fill">
                <mat-label>Jurisdiction</mat-label>
                <mat-select formControlName="jurisdiction" placeholder="Select jurisdiction" required>
                  <mat-option *ngFor="let jurisdiction of jurisdictions" [value]="jurisdiction.code">
                    <span [appCountryFlag]="jurisdiction.code"></span>
                    {{ jurisdiction.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="field(fields.Jurisdiction).touched && field(fields.Jurisdiction).invalid">
                  Please select the company's jurisdiction
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="45" appearance="fill">
                <mat-label>Registration ID</mat-label>
                <input type="text" formControlName="registrationId" matInput placeholder="Registration ID" required />
                <mat-error *ngIf="field(fields.RegistrationId).touched && field(fields.RegistrationId).invalid">
                  Please enter the registration id
                </mat-error>
              </mat-form-field>
            </div>
            <div style="margin-top: 20px;">
              <mat-form-field appearance="fill">
                <mat-label>Registration Authority</mat-label>
                <mat-select formControlName="registrationAuthority" placeholder="Registration Authority" required>
                  <ng-container *ngFor="let authority of regAuthorities">
                    <mat-option
                      [value]="authority"
                      *ngIf="authority.internationalNameOfOrganisationRegister || authority.internationalNameOfRegister"
                    >
                      <span [appCountryFlag]="authority.countryCode"></span>
                      {{ authority.jurisdiction }} -
                      {{ authority.internationalNameOfRegister || authority.internationalNameOfOrganisationRegister }}
                    </mat-option>
                  </ng-container>
                  <mat-error
                    *ngIf="field(fields.RegistrationAuthority).touched && field(fields.RegistrationAuthority).invalid"
                  >
                    Please select a registration authority
                  </mat-error>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 20px;">
              <mat-form-field fxFlex="45" appearance="fill">
                <mat-label>Registration Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="incorporationDate"
                  (focus)="picker.open()"
                  required
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="field(fields.IncorporationDate).touched && field(fields.IncorporationDate).invalid">
                  You need to enter the registration date of the company
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="50" appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" required>
                  <mat-option *ngFor="let status of statusOptions" [value]="status">
                    {{ status }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="field(fields.Status).touched && field(fields.Status).invalid">
                  Please select an option matching the status of the company
                </mat-error>
              </mat-form-field>
            </div>
            <div style="margin-top: 20px;">
              <mat-form-field appearance="fill">
                <mat-label>Legal Entity Form</mat-label>
                <mat-select
                  formControlName="legalEntityForm"
                  placeholder="Legal entity form as declared at registration"
                  required
                >
                  <mat-option *ngFor="let legalEntityForm of legalEntityForms" [value]="legalEntityForm">
                    {{ legalEntityForm }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="field(fields.LegalEntityForm).touched && field(fields.LegalEntityForm).invalid">
                  Select the legal entity form
                </mat-error>
              </mat-form-field>
            </div>
            <div class="" *ngIf="searchComplete">
              <mat-checkbox>Edit company identity manually</mat-checkbox>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start" class="form-section">
          <div fxFlex="33">
            <h4>Company Name</h4>
          </div>
          <div fxFlex="66">
            <div>
              <mat-form-field [appearance]="'fill'">
                <mat-label>Company Name</mat-label>
                <input type="text" formControlName="name" matInput placeholder="E.g Contingent Tech" required />
                <mat-error *ngIf="field(fields.CompanyName).touched && field(fields.CompanyName).invalid"
                  >The company name is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start" class="form-section">
          <div fxFlex="33">
            <h4>Address</h4>
          </div>
          <div fxFlex="66">
            <app-address-form formGroupName="address" [submitted]="submitAttempted"></app-address-form>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start" class="form-section">
          <div fxFlex="33">
            <h4>Evidence</h4>
          </div>
          <div fxFlex="66">
            <app-evidence-definition-form formControlName="evidence"></app-evidence-definition-form>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end none" class="buttons">
          <a mat-stroked-button type="button" routerLink="/" *ngIf="!submitting" style="margin-right: 10px;">Back</a>
          <button mat-raised-button color="primary" type="submit" [disabled]="companyForm.invalid || submitting">
            Submit{{ submitting ? 'ting...' : '' }}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
