import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';
import { MenuService } from '../menu/menu.service';
import { UserService } from '../../core/services/core/user.service';
import { AuthService } from '../../core/services/core/auth.service';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Output()
  toggleSidenav = new EventEmitter<void>();
  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();
  searchQuery: string;
  image: string;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public menuService: MenuService,
  ) {
    this.image = this.userService.getCurrentUser().photoURL;
  }

  fullScreenToggle(): void {
    if ((screenfull as Screenfull).enabled) {
      (screenfull as Screenfull).toggle();
    }
  }

  search(): void {
    this.router.navigate(['/search', { query: this.searchQuery }]);
  }
}
