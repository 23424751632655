import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPropagator, OTLP_PROPAGATOR } from '@jufab/opentelemetry-angular-interceptor';
import { environment } from '../../../environments/environment';

@Injectable()
export class TelemetryHeaderFilterInterceptor implements HttpInterceptor {
  constructor(
    @Inject(OTLP_PROPAGATOR)
    private propagatorService: IPropagator,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRequestToTracedApi(request)) {
      request = request.clone({
        headers: this.propagatorService
          .getPropagator()
          .fields()
          .reduce((headers, header) => headers.delete(header), request.headers),
      });
    }

    return next.handle(request);
  }

  isRequestToTracedApi(request: HttpRequest<any>) {
    return Object.values(environment).find((apiUrl) => typeof apiUrl === 'string' && request.url.startsWith(apiUrl));
  }
}
