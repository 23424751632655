import { gql } from 'graphql-request';

export const FINANCIAL_SUMMARY = gql`
  query FinancialSummary($id: String!) {
    financialSummary(id: $id) {
      financialSummary {
        latestCreditScoreRating {
          ...FinancialMetricFlat
        }
        yearlySummaries {
          accountingSystem
          periodEnd
          scores {
            hScoreIndustryAverage {
              ...FinancialMetricFlat
            }
            hScore {
              ...FinancialMetricFlat
            }
            riskRating {
              ...FinancialMetricFlat
            }
            probabilityOfDistressTwelveMonths {
              ...FinancialMetricFlat
            }
            probabilityOfDistressThreeYears {
              ...FinancialMetricFlat
            }
            creditSafeRating {
              ...FinancialMetricFlat
            }
            creditSafeAlphanumericRating {
              ...FinancialMetricFlat
            }
          }
          ratios {
            currentRatio {
              ...FinancialMetricFlat
            }
            operatingProfitMargin {
              ...FinancialMetricFlat
            }
          }
          balanceSheet {
            netDebt {
              ...MoneyFlat
            }
            operatingCashFlow {
              ...MoneyFlat
            }
            netAssets {
              ...MoneyFlat
            }
            issuedShareCapital {
              ...MoneyFlat
            }
          }
          incomeStatement {
            ebitda {
              ...MoneyFlat
            }
            operatingProfit {
              ...MoneyFlat
            }
            sales {
              ...MoneyFlat
            }
            numberOfEmployees
          }
        }
      }
    }
  }

  fragment FinancialMetricFlat on FinancialMetric {
    date
    value
    commonValue
    description
    formattedValue
  }

  fragment MoneyFlat on Money {
    shortFormattedValue
    formattedValue
    value
    currency
    description
  }
`;
