import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessesApiService } from '../../core/services/apis/businesses-api.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  loading = false;
  summaries = [];

  constructor(
    private route: ActivatedRoute,
    private businessesApiService: BusinessesApiService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.queryParams.subscribe((params) => {
      this.businessesApiService.getSpreadsheetSummaryById(params.spreadsheetId).subscribe((res) => {
        const key = 'requestedReports';
        this.summaries = res[key];
        this.loading = false;
      });
    });
  }

  done() {
    this.router.navigate(['/']);
  }
}
