// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/en-GB';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeedbackEnvironmentToken } from '@contingent/shared/ui-feedback-button';
import { SharedUiNavModule } from '@contingent/shared/ui-nav';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GraphQLModule } from './graphql.module';
import { RequestsModule } from './requests/requests.module';
import { SharedModule } from './shared/shared.module';
import { SharedSvgIconsModule } from '@contingent/shared/svg-icons';
import {
  OpenTelemetryConfig,
  OpenTelemetryInterceptorModule,
  OtelColExporterModule,
  OtelWebTracerModule,
  W3CTraceContextPropagatorModule,
} from '@jufab/opentelemetry-angular-interceptor';
import { TelemetryHeaderFilterInterceptor } from '../app/core/interceptors/telemetry-header-filter.interceptor';
import { AssessmentEnvironmentToken } from '@contingent/workbench/assessment/api';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const opentelemetrySettings: OpenTelemetryConfig = {
  commonConfig: {
    console: false,
    production: true,
    serviceName: 'workbench',
    probabilitySampler: '1', // 100% sampling
    logLevel: 99,
  },
  instrumentationConfig: {
    xmlHttpRequest: false,
    fetch: false,
    documentLoad: false,
  },
  otelcolConfig: {
    url: environment.telemetryUrl,
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    RequestsModule,
    CoreModule,
    SharedModule,
    FormsModule,
    GraphQLModule,
    HttpClientModule,
    SharedSvgIconsModule,
    SharedUiNavModule,
    BrowserAnimationsModule,
    OpenTelemetryInterceptorModule.forRoot(opentelemetrySettings),
    OtelColExporterModule,
    W3CTraceContextPropagatorModule,
    OtelWebTracerModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
    {
      provide: FeedbackEnvironmentToken,
      useValue: {
        publishableKey: environment.waitlystPublishableKey,
      },
    },
    {
      provide: AssessmentEnvironmentToken,
      useValue: {
        workbenchApiUri: environment.workbenchApiUri,
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: TelemetryHeaderFilterInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
