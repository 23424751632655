import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'contingent-nav-item, [contingentNavItem]',
})
export class ContingentNavItem {
  @HostBinding('class.ui-contingent-nav-item') navItem = true;
}

@Directive({
  selector: 'contingent-nav-header, [contingentNavHeader]',
})
export class ContingentNavHeader {
  @HostBinding('class.ui-contingent-nav-header') navHeader = true;
}

@Directive({
  selector: 'contingent-nav-content, [contingentNavContent]',
})
export class ContingentNavContent {
  @HostBinding('class.ui-contingent-nav-content') navContent = true;
}

@Directive({
  selector: 'contingent-nav-footer, [contingentNavFooter]',
})
export class ContingentNavFooter {
  @HostBinding('class.ui-contingent-nav-footer') navFooter = true;
}

@Directive({
  selector: 'contingent-sidenav, [contingentSidenav]',
})
export class ContingentSideNav {
  @HostBinding('class.ui-contingent-sidenav') sideNav = true;
}
