import { FormGroup, ValidationErrors } from '@angular/forms';

export function percentageInputValidator(isExact: string, exactPercentage: string) {
  return (group: FormGroup): ValidationErrors | undefined => {
    console.log(group.controls[isExact].value);
    console.log(group.controls[exactPercentage].value);

    // if (group.controls[isExact].value !== group.controls[exactPercentage].value) {
    //   return {
    //     missmatch: true,
    //   };
    // }
    return undefined;
  };
}
