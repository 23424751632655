<ng-container [formGroup]="controlContainer.control">
  <div [class.has-error]="formInvalid && submitted" class="address-form">
    <mat-form-field appearance="fill">
      <mat-label>First Line of Address</mat-label>
      <input matInput formControlName="firstLineOfAddress" placeholder="Address Line" required />
    </mat-form-field>
    <div class="split-fields">
      <mat-form-field appearance="fill">
        <mat-label>Country of residence</mat-label>
        <mat-select formControlName="countryCode" placeholder="Select country of residence" required>
          <mat-option *ngFor="let jurisdiction of jurisdictions" [value]="jurisdiction.code">
            <span [appCountryFlag]="jurisdiction.code"></span>
            {{ jurisdiction.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>City</mat-label>
        <input matInput formControlName="city" placeholder="City" required />
      </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>Additional Line of Address</mat-label>
      <input matInput formControlName="additionalLineOfAddress" placeholder="Address Line" />
    </mat-form-field>
    <div class="split-fields">
      <mat-form-field appearance="fill">
        <mat-label>Postal Code</mat-label>
        <input matInput formControlName="postalCode" placeholder="Postal Code" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Region Code</mat-label>
        <input matInput formControlName="regionCode" placeholder="Enter a region code" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Premises</mat-label>
        <input matInput formControlName="premises" placeholder="Premises" />
      </mat-form-field>
    </div>
  </div>
</ng-container>
