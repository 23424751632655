import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppFormsModule } from '@workbench-root/forms/app-forms.module';
import { MessageService } from '../shared/error-notification/message.service';
import { SharedModule } from '../shared/shared.module';
import { NewCompanyComponent } from './new-company/new-company.component';
import { RequestsRoutes } from './requests-routing.module';
import { SummaryComponent } from './summary/summary.component';

@NgModule({
  declarations: [SummaryComponent, NewCompanyComponent],
  imports: [CommonModule, RouterModule.forChild(RequestsRoutes), SharedModule, AppFormsModule],
  providers: [MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [],
})
export class RequestsModule {}
