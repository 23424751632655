import { Pipe, PipeTransform } from '@angular/core';
import _moment from 'moment';
@Pipe({
  name: 'formatMonthYear',
})
export class FormatMonthYearPipe implements PipeTransform {
  transform(val) {
    if (!val) {
      return '';
    }

    return _moment({
      month: val.month - 1,
      year: val.year,
    }).format('MMM YYYY');
  }
}
