import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@workbench-root/shared/shared.module';
import { AddressFormComponent } from './address-form/address-form.component';
import { EvidenceDefinitionFormComponent } from './evidence-definition-form/evidence-definition-form.component';
import { ShareDefinitionFormComponent } from './share-definition-form/share-definition-form.component';

@NgModule({
  imports: [MatSelectModule, ReactiveFormsModule, SharedModule],
  providers: [ShareDefinitionFormComponent, EvidenceDefinitionFormComponent, AddressFormComponent],
  declarations: [AddressFormComponent, EvidenceDefinitionFormComponent, ShareDefinitionFormComponent],
  exports: [AddressFormComponent, EvidenceDefinitionFormComponent, ShareDefinitionFormComponent],
})
export class AppFormsModule {}
