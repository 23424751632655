import { Pipe, PipeTransform } from '@angular/core';
import { getName } from 'country-list';
import iso31662 from 'iso-3166-2';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return undefined;
    }

    if (value.length > 2) {
      const subDivision = iso31662.subdivision(value);
      if (subDivision) {
        return `${subDivision.name}, ${subDivision.countryName}`;
      }
      return getName(value.substring(0, 2));
    }

    return getName(value);
  }
}
