import { Address } from '@contingenton/types';

export interface NewsArticle {
  id: string;
  entityId: string;
  title: string;
  relevantDomains: string[];
  relevantTopics: string[];
  matchingEntity: string;
  matchStatus: MatchStatus;
  summary: string;
  pageUrl: string;
  text: string;
  date: Date;
  image: string;
  sourceUri: string;
  resolvedPageUrl: string;
  siteName?: string;
  crawlTimestamp: string;
  publishedTimestamp: string;
  estimatedDate: Date;
  language: string;
  score: number;
  occurrences: number;
  author?: string;
  sentiment?: number;
}

export enum NewsSource {
  Diffbot = 'Diffbot',
  Manual = 'Manual',
}

export interface NewsEntity {
  source: NewsSource;
  id: string;
  matchStatus?: MatchStatus;
  name?: string;
  importance?: number;
  origin?: string;
  description?: string;
  homepageUri?: string;
  allNames?: string[];
  origins?: string[];
  logo?: string;
  image?: string;
  crawlTimestamp?: Date;
  allUris?: string[];
  twitterUri?: string;
  facebookUri?: string;
  wikipediaUri?: string;
  crunchbaseUri?: string;
  linkedInUri?: string;
  industries?: string[];
  allDescriptions?: string[];
  location?: Address;
  sentiment: number;
  newsArticles: NewsArticle[];
}

export enum MatchStatus {
  PotentialMatch = 'PotentialMatch',
  TruePositive = 'TruePositive',
  FalsePositive = 'FalsePositive',
}

export interface NewsEntityMutateResponse {
  updateNewsEntityStatus: NewsEntity;
}

export interface NewsArticleMutateResponse {
  updateNewsArticleStatus: NewsArticle;
}

interface AssessmentQueryResponse {
  newsEntities: Array<NewsEntity>;
}

export interface NewsEntitiesQueryResponse {
  assessment: AssessmentQueryResponse;
}

export interface NewsArticlesQueryResponse {
  assessment: AssessmentQueryResponse;
}

export interface FetchNewsArticlesResponse {
  fetchNewsArticles: Array<NewsArticle>;
}

export interface FetchNewsEntitiesQueryResponse {
  fetchNewsEntities: Array<NewsEntity>;
}

export interface NewsArticleInputType {
  title: string;
  summary?: string;
  text?: string;
  date: Date;
  estimatedDate: Date;
  crawlTimestamp?: Date;
  publishedTimestamp: Date;
  image?: string;
  sourceUri?: string;
  pageUrl?: string;
  resolvedPageUrl?: string;
  siteName?: string;
  language?: string;
  score?: number;
  occurrences?: number;
  author?: string;
  sentiment?: number;
  relevantDomains: NewsDomain[];
  relevantTopics: NewsTopic[];
  matchingEntity: string;
}

export enum NewsTopic {
  Fundraising = 'Fundraising',
  FinancialResults = 'FinancialResults',
  CompanyCreditRating = 'CompanyCreditRating',
  InsolvencyBankruptcy = 'InsolvencyBankruptcy',
  InvestmentAndExpansion = 'InvestmentAndExpansion',

  Tenders = 'Tenders',
  Projects = 'Projects',
  Contracts = 'Contracts',
  HiringLayoffs = 'HiringLayoffs',
  Facilities = 'Facilities',

  Divestment = 'Divestment',
  MergersAndAcquisition = 'MergersAndAcquisition',
  KeyExecutiveManagementChanges = 'KeyExecutiveManagementChanges',

  Sanction = 'Sanction',
  Corruption = 'Corruption',
  TaxEvasion = 'TaxEvasion',
  OrganisedCrime = 'OrganisedCrime',
  TerroristFinancing = 'TerroristFinancing',
  AntitrustAndCompetition = 'AntitrustAndCompetition',
  FinancialMarketsRegulation = 'FinancialMarketsRegulation',
  FinancialCrimes = 'FinancialCrimes',

  Hacking = 'Hacking',
  DataBreach = 'DataBreach',
  CyberAttacks = 'CyberAttacks',
  MalwareSecurity = 'MalwareSecurity',
  OutageDowntime = 'OutageDowntime',

  Sustainability = 'Sustainability',
  ModernSlavery = 'ModernSlavery',
  HealthAndSafety = 'HealthAndSafety',
  EthicsAndGovernance = 'EthicsAndGovernance',
  EnvironmentalImpact = 'EnvironmentalImpact',
  DiversityEqualityAndInclusion = 'DiversityEqualityAndInclusion',
}

export enum NewsDomain {
  General = 'General',
  FinancialStability = 'FinancialStability',
  RegulatoryCompliance = 'RegulatoryCompliance',
  OwnershipAndControl = 'OwnershipAndControl',
  CapacityQualityAndCompliance = 'CapacityQualityAndCompliance',
  EnvironmentalSocialAndGovernance = 'EnvironmentalSocialAndGovernance',
  CyberSecurity = 'CyberSecurity',
  MarketsAndPrices = 'MarketsAndPrices',
}
