import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyReport, CountryCode, Evidence } from '@contingenton/types';
import {
  ADD_SCREENING_EVENTS,
  ADD_SCREENING_RESULT,
  SET_DEFAULT_SCREENING_EVENTS,
  GET_SCREENING_EVENTS_FROM_BUSINESS,
  MATCHING_SCREENED_BUSINESSES,
  UPDATE_CONTACT_INFORMATION,
  DELETE_CONTACT_INFORMATION,
  UPDATE_DESCRIPTION,
  UPDATE_HEADQUARTERS_ADDRESS,
  UPDATE_INDUSTRY_CLASSIFICATION,
  UPDATE_LEGAL_ADDRESS,
  UPDATE_LEGAL_ENTITY,
  UPDATE_NAME,
  UPDATE_PREVIOUS_NAMES,
  UPDATE_REGISTRATION_AUTHORITY,
  UPDATE_TRADE_NAMES,
  UPDATE_TRANSLITERATED_NAME,
} from '@workbench-root/core/services/graphql-queries';
import { NameUpdateTypes } from '@workbench-root/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GraphQLService } from './graphQL.service';
import { ScreeningEvent } from '@contingent/assessment/domain';

@Injectable({ providedIn: 'root' })
export class BusinessesApiService {
  currentCompanyReport$ = new BehaviorSubject<CompanyReport>(null);

  constructor(private httpClient: HttpClient, public graphQLClient: GraphQLService) {}

  getSingle(id): Observable<CompanyReport> {
    return this.httpClient.get<CompanyReport>(`${environment.workbenchApiUri}requested-reports/${id}`);
  }

  getSpreadsheetSummaryById(id: string) {
    return this.httpClient.get(`${environment.workbenchApiUri}spreadsheets/${id}`);
  }

  updateName(businessId: string, name: string, type: NameUpdateTypes) {
    const query = type === NameUpdateTypes.Name ? UPDATE_NAME : UPDATE_TRANSLITERATED_NAME;
    const variables = {
      id: businessId,
      name,
    };

    return this.graphQLClient.execute(query, variables);
  }

  updateDescription(businessId: string, description: string, evidence: Evidence) {
    const variables = {
      id: businessId,
      description: {
        description,
        evidence: {
          ...evidence,
          retrievedAt: new Date(),
        },
      },
    };
    return this.graphQLClient.execute(UPDATE_DESCRIPTION, variables);
  }

  updateContactDetails(businessId: string, websites: string[], evidence: Evidence) {
    const variables = {
      id: businessId,
      contactInformation: {
        websiteUrls: websites,
        evidence,
      },
    };
    return this.graphQLClient.execute(UPDATE_CONTACT_INFORMATION, variables);
  }

  deleteContactDetails(businessId: string) {
    const variables = {
      businessId: businessId,
    };
    return this.graphQLClient.execute(DELETE_CONTACT_INFORMATION, variables);
  }

  async updateRegistrationAuthority(
    businessId: string,
    registrationAuthority: any, // TODO: This needs to be a type
    jurisdiction: CountryCode,
    evidence: Evidence,
  ) {
    const variables = {
      id: businessId,
      registrationInformation: {
        jurisdiction,
        registrationAuthorityCode: registrationAuthority.registrationAuthorityCode,
        registrationDate: registrationAuthority.registrationDate,
        id: registrationAuthority.id,
        evidence,
      },
    };
    return this.graphQLClient.execute(UPDATE_REGISTRATION_AUTHORITY, variables);
  }

  updateLegalAddress(businessId: string, address: any, evidence: Evidence) {
    const variables = {
      id: businessId,
      legalAddress: {
        address,
        evidence,
      },
    };
    return this.graphQLClient.execute(UPDATE_LEGAL_ADDRESS, variables);
  }

  updateHeadquartersAddress(businessId: string, address: any, evidence: Evidence) {
    const variables = {
      id: businessId,
      headquartersAddress: {
        address,
        evidence,
      },
    };
    return this.graphQLClient.execute(UPDATE_HEADQUARTERS_ADDRESS, variables);
  }

  async updateLegalEntity(companyId: string, command) {
    const variables = {
      id: companyId,
      business: command,
    };

    return this.graphQLClient.execute(UPDATE_LEGAL_ENTITY, variables);
  }

  updateIndustryClassification(companyId: string, command: any) {
    const variables = {
      id: companyId,
      business: command,
    };

    return this.graphQLClient.execute(UPDATE_INDUSTRY_CLASSIFICATION, variables);
  }

  updatePreviousNames(companyId: string, command: any) {
    const variables = {
      id: companyId,
      business: command,
    };

    return this.graphQLClient.execute(UPDATE_PREVIOUS_NAMES, variables);
  }

  async updateTradeNames(companyId: string, business: { tradeNames: string[] }) {
    const variables = {
      id: companyId,
      business,
    };

    return this.graphQLClient.execute(UPDATE_TRADE_NAMES, variables);
  }

  async addLegacyScreeningResult(businessId: string, screeningResults: any[]) {
    const variables = {
      businessId,
      result: {
        screeningResults,
      },
    };

    return this.graphQLClient.execute(ADD_SCREENING_RESULT, variables);
  }

  async addScreeningEvents(businessId: string, events: ScreeningEvent[]) {
    const variables = {
      businessId,
      data: {
        events,
      },
    };

    return this.graphQLClient.execute(ADD_SCREENING_EVENTS, variables);
  }

  approveRequest(id: string) {
    const variables = {
      input: {
        id,
      },
    };
    const query = `
      mutation publish($input: AssessmentArgsType!) {
          publish(assessment: $input) {
            id
          }
        }
  `;
    return this.graphQLClient.execute(query, variables);
  }

  async rebuild(id: string) {
    const variables = {
      input: {
        id,
      },
    };
    const query = `
      mutation rebuild($input: AssessmentArgsType!) {
          rebuild(assessment: $input) {
            id
          }
        }
  `;
    return this.graphQLClient.execute(query, variables);
  }

  getExcelLink(id: string) {
    return this.httpClient.get(`${environment.workbenchApiUri}spreadsheets/${id}/document`, {});
  }

  getPdfLink(id: string) {
    return this.httpClient.get(`${environment.workbenchApiUri}documents/${id}/pdf`, {});
  }

  getDocLink(id: string) {
    return this.httpClient.get(`${environment.workbenchApiUri}documents/${id}/docx`, {});
  }

  declineRequest(id: string) {
    return this.httpClient.delete(`${environment.workbenchApiUri}requested-reports/${id}`, {});
  }

  setDefaultScreeningEvents(id: string) {
    const variables = {
      id,
    };
    return this.graphQLClient.execute(SET_DEFAULT_SCREENING_EVENTS, variables);
  }

  getMatchingScreenedBusinesses(id: string) {
    const variables = {
      id,
    };
    return this.graphQLClient.execute(MATCHING_SCREENED_BUSINESSES, variables);
  }

  getScreeningEventsFrom(id: string, business: any) {
    const variables = {
      id,
      business,
    };
    return this.graphQLClient.execute(GET_SCREENING_EVENTS_FROM_BUSINESS, variables);
  }

  generatePreview(id: string) {
    return this.httpClient.post(`${environment.workbenchApiUri}requested-reports/${id}/preview`, {});
  }
}
