import { CommonModule } from '@angular/common';
import { ElementRef, NgModule } from '@angular/core';
import {
  ContingentNavContent,
  ContingentNavFooter,
  ContingentNavHeader,
  ContingentNavItem,
  ContingentSideNav,
} from './nav';
import { ContingentNavComponent } from './nav.component';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  imports: [MatSidenavModule, CommonModule],
  declarations: [
    ContingentNavComponent,
    ContingentNavItem,
    ContingentNavHeader,
    ContingentNavContent,
    ContingentNavFooter,
    ContingentSideNav,
  ],
  exports: [
    ContingentNavComponent,
    ContingentNavItem,
    ContingentNavHeader,
    ContingentNavContent,
    ContingentNavFooter,
    ContingentSideNav,
  ],
  providers: [],
})
export class SharedUiNavModule {}
