import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

export interface EvidenceFormValues {
  source: string;
  publicationDate: Date;
  publisherName: string;
  url: string;
  snippet: string;
}

// eslint-disable-next-line no-useless-escape
export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

@Component({
  selector: 'app-evidence-definition-form',
  templateUrl: './evidence-definition-form.component.html',
  styleUrls: ['./evidence-definition-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => EvidenceDefinitionFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => EvidenceDefinitionFormComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvidenceDefinitionFormComponent implements ControlValueAccessor, OnDestroy {
  form: FormGroup;
  subscriptions: Subscription[] = [];

  get value(): EvidenceFormValues {
    return this.form.value;
  }

  set value(value: EvidenceFormValues) {
    this.form.setValue(value, { emitEvent: false });
    this.onChange(value);
    this.onTouched();
  }

  get source() {
    return this.form.get('source');
  }

  get publisherName() {
    return this.form.get('publisherName');
  }

  get publicationDate() {
    return this.form.get('publicationDate');
  }

  onChange: any;
  onTouched: any;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      source: [Validators.required],
      publicationDate: [Validators.required],
      publisherName: [Validators.required],
      url: [null, Validators.pattern(URL_REGEX)],
      snippet: [],
    });

    this.form.controls.publicationDate.setValue(new Date());

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        if (this.onChange) {
          this.onChange(value);
          this.onTouched();
        }
      }),
    );
  }

  get URLInvalid(): boolean {
    return this.form.get('url').invalid;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { evidence: { valid: false } };
  }
}
