import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GraphQLService } from '@workbench-root/core/services/apis/graphQL.service';
import { Apollo, gql } from 'apollo-angular';

export const GET_ALL_ASSESSMENTS: any = gql`
  query allAssessmentsQuery($input: AssessmentQueryArgsType!) {
    assessments(queryArgs: $input) {
      results {
        id
        entityName
        jurisdictionCode
        registrationId
        assessmentType
        assessmentStatus {
          overallStatus
        }
      }
      jurisdictions {
        jurisdictionCode
        count
        selected
      }
      statuses {
        status
        count
        selected
      }
      paging {
        skip
        take
        totalResults
      }
      sort {
        name
        direction
      }
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class AssessmentsApiService {
  constructor(private httpClient: HttpClient, private apollo: Apollo, private graphqlClient: GraphQLService) {}

  getAllAssessments(value: string): Promise<any> {
    const variables = {
      input: { query: value },
    };
    return this.graphqlClient.execute(GET_ALL_ASSESSMENTS, variables);
  }
}
