import { Routes } from '@angular/router';
import { NewCompanyComponent } from '@workbench-root/requests/new-company/new-company.component';
import { SummaryComponent } from './summary/summary.component';

export const RequestsRoutes: Routes = [
  {
    path: ':assessmentType/:id',
    loadChildren: () => import('./details/details.module').then((m) => m.DetailsModule),
  },
  {
    path: 'summary',
    component: SummaryComponent,
  },
  {
    path: 'new-company',
    component: NewCompanyComponent,
  },
];
