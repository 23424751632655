<mat-form-field>
  <input matInput [matDatepicker]="dp" placeholder="Date of Birth" [formControl]="date" (focus)="dp.open()" />
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker
    #dp
    startView="multi-year"
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event, dp)"
    panelClass="example-month-picker"
  >
  </mat-datepicker>
  <mat-hint align="start">Month and Year</mat-hint>
</mat-form-field>
