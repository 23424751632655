import { Injectable } from '@angular/core';
import { GraphQLClient } from 'graphql-request';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../core/auth.service';
import { UserService } from '../core/user.service';

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  constructor(private auth: AuthService, private userService: UserService) {
    const url = `${environment.workbenchApiUri}graphql`;
    const token: string = this.userService.getCurrentUser() ? this.userService.getCurrentUser().idToken : null;
    const headers = {
      authorization: `Bearer ${token}`,
    };
    this.client = GraphQLService.initClient(url, headers);
  }

  private get token(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.auth.getIdToken().subscribe((res: string) => {
        if (res) {
          resolve(res);
        }
        reject();
      });
    });
  }

  private readonly client: GraphQLClient;

  private static initClient(url, headers: any) {
    return new GraphQLClient(url, {
      headers,
    });
  }

  private async refreshToken(client: GraphQLClient): Promise<GraphQLClient> {
    const token = await this.token;
    client.setHeader('Authorization', `Bearer ${token}`);
    return client;
  }

  async legacyApiRequest(query: string, variables = null) {
    if (this.auth.checkIfExpired()) {
      await this.refreshToken(this.client);
    }
    return this.client.request(query, variables);
  }

  async execute(query: string, variables = null) {
    const token = this.userService.getCurrentUser() ? this.userService.getCurrentUser().idToken : null;
    const headers = {
      authorization: `Bearer ${token}`,
    };
    const client = GraphQLService.initClient(environment.workbenchGraphQLApi, headers);

    if (this.auth.checkIfExpired()) {
      await this.refreshToken(client);
    }

    return client.request(query, variables);
  }
}
