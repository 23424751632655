import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FeedbackEnvironmentToken } from './feedback.api';

@Component({
  selector: 'contingent-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackButtonComponent {
  publishableKey = this.environment.publishableKey;
  constructor(@Inject(FeedbackEnvironmentToken) private environment) {}
}
