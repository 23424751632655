import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { environment } from '../../environments/environment';
import { ResearchWebBearerTokenInterceptor } from './interceptors/http.interceptor';
import {
  BusinessesApiService,
  DiscoveredEntitiesApiService,
  OfficersApi,
  UncommittedEventsGQLService,
} from './services/apis';
import { AuthGuard } from './services/core/auth.guard';
import { AuthService } from './services/core/auth.service';
import { NewsApi } from '@workbench-root/core/services/apis/news-api-service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

@NgModule({
  imports: [HttpClientModule, AngularFireAuthModule, AngularFireModule.initializeApp(environment.firebase)],
  providers: [
    BusinessesApiService,
    DiscoveredEntitiesApiService,
    UncommittedEventsGQLService,
    OfficersApi,
    HttpClient,
    AuthService,
    AuthGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ResearchWebBearerTokenInterceptor, multi: true },
    Apollo,
    HttpLink,
    NewsApi,
  ],
})
export class CoreModule {}
