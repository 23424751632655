import { gql } from 'graphql-request';

export const EVIDENCE = gql`
  query Evidence($id: String!) {
    evidence(id: $id) {
      sourceType
      relatedTo
      category
      source
      retrievedAt
      publicationDate
      publisherName
      url
      snippet
      context
    }
  }
`;
