import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { percentageInputValidator } from './validators';

export interface ShareDefinitionFormValues {
  interests: [any];
  interestType: string;
  isExact: boolean;
  exactPercentage: number;
  minPercentage: number;
  maxPercentage: number;
}

export const InterestTypes = [
  { value: 'shareholding', text: 'Shareholding' },
  { value: 'votingRights', text: 'Voting rights' },
  { value: 'appointmentOfBoard', text: 'Rights to appointment of board' },
  { value: 'otherInfluenceOrControl', text: 'Other influence or control rights' },
  { value: 'seniorManagingOfficial', text: 'Senior managing official' },
  { value: 'settlorOfTrust', text: 'Settlor of trust' },
  { value: 'trusteeOfTrust', text: 'Trustee of trust' },
  { value: 'protectorOfTrust', text: 'Protector of trust' },
  { value: 'beneficiaryOfTrust', text: 'Beneficiary of trust' },
  { value: 'otherInfluenceOrControlOfTrust', text: 'Other influence or control of trust' },
  { value: 'rightsToAppointAndRemoveDirectors', text: 'Rights to appoint and remove directors' },
  { value: 'rightsToSurplusAssetsOnDissolution', text: 'Rights to surplus assets on dissolution' },
  { value: 'rightsToProfitOrIncome', text: 'Rights to profit or income' },
  { value: 'rightsGrantedByContract', text: 'Rights granted by contract' },
  { value: 'conditionalRightsGrantedByContract', text: 'Conditional rights granted by contract' },
];

@Component({
  selector: 'app-share-definition-form',
  templateUrl: './share-definition-form.component.html',
  styleUrls: ['./share-definition-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ShareDefinitionFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ShareDefinitionFormComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareDefinitionFormComponent implements ControlValueAccessor, OnDestroy, OnInit {
  form: FormGroup;
  subscriptions: Subscription[] = [];
  interestTypes = InterestTypes;

  onChange: any;
  onTouched: any;

  get value(): ShareDefinitionFormValues {
    return this.form.value;
  }

  set value(value: ShareDefinitionFormValues) {
    this.form.setValue(value);
    if (this.onChange) {
      this.onChange(value);
      this.onTouched();
    }
  }

  get isExact() {
    return this.form.get('isExact').value;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset({
        interestType: 'shareholding',
        isExact: true,
      });
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { share: { valid: false } };
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        interestType: ['shareholding', Validators.required],
        isExact: [true],
        exactPercentage: [],
        minPercentage: [],
        maxPercentage: [],
      },
      { validator: percentageInputValidator('isExact', 'exactPercentage') },
    );

    console.log(this.form.value);

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        if (this.onChange) {
          this.onChange(value);
          this.onTouched();
        }
      }),
    );
  }
}
