import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentDto } from '@workbench-root/core/services/apis/comments.service';

export const GET_INSIGHTS = gql`
  query insights($businessId: String!) {
    insights(businessId: $businessId) {
      id
      businessId
      snippet
      domain
      category
      effectiveDate
      sentiment
      createdTimestamp
      author {
        id
        name
      }
    }
  }
`;

export const ADD_INSIGHT = gql`
  mutation createInsight($input: InsightInputType!) {
    createInsight(insight: $input) {
      businessId
      snippet
      domain
      sentiment
    }
  }
`;

export const UPDATE_INSIGHT = gql`
  mutation updateInsight($businessId: String!, $insightId: String!, $input: InsightInputType!) {
    updateInsight(businessId: $businessId, insightId: $insightId, insight: $input) {
      id
      businessId
      snippet
      domain
      sentiment
      createdTimestamp
      author {
        id
        name
      }
    }
  }
`;

export const DELETE_INSIGHT = gql`
  mutation deleteInsight($businessId: String!, $insightId: String!) {
    deleteInsight(businessId: $businessId, insightId: $insightId) {
      id
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class InsightsGQLService {
  constructor(private apollo: Apollo) {}

  addInsights(businessId: string, addInsightForm: any) {
    const { insightId, ...insight } = addInsightForm;

    return this.apollo
      .watchQuery<InsightsResponse>({
        query: ADD_INSIGHT,
        variables: {
          input: {
            businessId: `${businessId}`,
            ...insight,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data));
  }

  deleteInsights(businessId: string, insightId: string) {
    return this.apollo.watchQuery<InsightsResponse>({
      query: DELETE_INSIGHT,
      variables: {
        businessId,
        insightId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  getInsights(businessId: string): Observable<Insight[]> {
    return this.apollo
      .watchQuery<InsightsResponse>({
        query: GET_INSIGHTS,
        variables: {
          businessId,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data.insights));
  }

  updateInsights(businessId: string, addInsightForm: any) {
    const { insightId, ...insights } = addInsightForm;

    return this.apollo
      .watchQuery<InsightsResponse>({
        query: UPDATE_INSIGHT,
        variables: {
          businessId,
          insightId,
          input: {
            businessId,
            ...insights,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data));
  }
}

export interface Insight {
  comments: CommentDto[];
  id: string;
  businessId: string;
  snippet: string;
  domain: string;
  sentiment: number;
  category?: string;
  createdTimestamp: Date;
  author: Author;
}

export interface Author {
  id: string;
  name: string;
}

export interface InsightsResponse {
  insights: Array<Insight>;
}
