import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(val, args) {
    if (args && val) {
      return val.length > args ? val.substring(0, args) + '...' : val;
    }

    return args;
  }
}
