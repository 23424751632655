import { Pipe, PipeTransform } from '@angular/core';
import registrationAuthoritiesJson from '@workbench-root/types/registrationAuthorities.json';

const { registrationAuthorities } = registrationAuthoritiesJson;

@Pipe({
  name: 'registrationAuthority',
})
export class RegistrationAuthorityPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return 'Not specified';
    }

    const entry = registrationAuthorities.find(
      (registrationAuthority) => registrationAuthority.registrationAuthorityCode === value,
    );
    if (!entry) {
      return 'Not specified';
    }

    return (
      entry.internationalNameOfOrganisationRegister ||
      entry.localNameOfRegister ||
      entry.localNameOfOrganisationRegister
    );
  }
}
