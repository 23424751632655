<div
  class="app mat-typography"
  [dir]="options.dir"
  [ngClass]="{
    'app-dark': options.dark,
    boxed: options.boxed,
    'collapsed-sidebar': options.collapsed,
    'compact-sidebar': options.compact,
    'side-panel-opened': sidePanelOpened,
    'side-panel-closed': !sidePanelOpened
  }"
>
  <ngx-loading-bar color="#009688"></ngx-loading-bar>
  <div [perfectScrollbar]="config" [disabled]="mediaMatcher.matches">
    <div class="contingent-container" data-cy="dashboard">
      <div class="contingent-nav">
        <contingent-nav data-cy="nav">
          <contingent-nav-header>
            <svg width="53" height="31" viewBox="0 0 53 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.6901 21.2714C18.3978 22.2406 16.7995 22.8188 15.0651 22.8188C10.8142 22.8188 7.37953 19.384 7.37953 15.1332C7.37953 10.8823 10.8142 7.44756 15.0651 7.44756C16.7995 7.44756 18.3978 8.02568 19.6901 8.99488C20.5063 6.59739 21.7645 4.42094 23.3969 2.55055C21.0164 0.969218 18.1428 0.0510254 15.0821 0.0510254C6.75041 0.0510254 0 6.80143 0 15.1332C0 23.4649 6.75041 30.2153 15.0821 30.2153C18.1598 30.2153 21.0164 29.2971 23.3969 27.7158C21.7645 25.8454 20.4893 23.6689 19.6901 21.2714Z"
                fill="#101E33"
              />
              <path
                d="M37.8665 0C29.5008 0 22.7334 6.76741 22.7334 15.1331C22.7334 23.4989 29.5008 30.2663 37.8665 30.2663C46.2323 30.2663 52.9997 23.4989 52.9997 15.1331C52.9997 6.76741 46.2153 0 37.8665 0ZM37.8665 22.8527C33.6157 22.8527 30.1469 19.401 30.1469 15.1331C30.1469 10.8823 33.5987 7.41354 37.8665 7.41354C42.1174 7.41354 45.5861 10.8653 45.5861 15.1331C45.5691 19.401 42.1174 22.8527 37.8665 22.8527Z"
                fill="url(#paint0_linear_8700_28085)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_8700_28085"
                  x1="28.4805"
                  y1="2.16297"
                  x2="48.5598"
                  y2="29.9418"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#23AFFF" />
                  <stop offset="0.3436" stop-color="#36BDF6" />
                  <stop offset="1" stop-color="#64DFDF" />
                </linearGradient>
              </defs>
            </svg>
            <button type="button" data-cy="search-button" class="search" (click)="openMatDialog()">
              <mat-icon>search</mat-icon>
            </button>
          </contingent-nav-header>
          <contingent-nav-content>
            <contingent-nav-item>
              <div
                [routerLink]="['/assessments']"
                routerLinkActive="active"
                #assessmentRla="routerLinkActive"
                data-cy="nav-item"
                class="item-container"
              >
                <div class="icon">
                  <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.25 1H3.625C2.52043 1 1.625 1.89543 1.625 3V13.875C1.625 14.9796 2.52043 15.875 3.625 15.875H9.25C10.3546 15.875 11.25 14.9796 11.25 13.875V3C11.25 1.89543 10.3546 1 9.25 1Z"
                      stroke="#23AFFF"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="square"
                    />
                    <path
                      d="M9.25 19.375H3.625C2.52043 19.375 1.625 20.2704 1.625 21.375V25.25C1.625 26.3546 2.52043 27.25 3.625 27.25H9.25C10.3546 27.25 11.25 26.3546 11.25 25.25V21.375C11.25 20.2704 10.3546 19.375 9.25 19.375Z"
                      stroke="#23AFFF"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="square"
                    />
                    <path
                      d="M22.375 1H16.75C15.6454 1 14.75 1.89543 14.75 3V6.875C14.75 7.97957 15.6454 8.875 16.75 8.875H22.375C23.4796 8.875 24.375 7.97957 24.375 6.875V3C24.375 1.89543 23.4796 1 22.375 1Z"
                      stroke="#23AFFF"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="square"
                    />
                    <path
                      d="M22.375 12.375H16.75C15.6454 12.375 14.75 13.2704 14.75 14.375V25.25C14.75 26.3546 15.6454 27.25 16.75 27.25H22.375C23.4796 27.25 24.375 26.3546 24.375 25.25V14.375C24.375 13.2704 23.4796 12.375 22.375 12.375Z"
                      stroke="#23AFFF"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
                <div class="text">Assessments</div>
              </div>
              <ul class="dropdown" *ngIf="assessmentRla.isActive">
                <li routerLink="/assessments/extended" routerLinkActive="active">Extended</li>
                <li routerLink="/assessments/standard" routerLinkActive="active">Standard</li>
                <li routerLink="/assessments/monitoring" routerLinkActive="active">Monitoring</li>
                <li routerLink="/assessments/unresolved" routerLinkActive="active">Unresolved</li>
                <li routerLink="/assessments/news-stream" routerLinkActive="active">News Stream</li>
              </ul>
            </contingent-nav-item>
            <!--            <contingent-nav-item [routerLink]="['/subscriptions/']" routerLinkActive="active" data-cy="nav-item">-->
            <!--              <div class="item-container">-->
            <!--                <div class="icon">-->
            <!--                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                    <path-->
            <!--                      d="M24.6667 24.6666L20.0001 20"-->
            <!--                      stroke="#8EABB8"-->
            <!--                      stroke-width="1.5"-->
            <!--                      stroke-miterlimit="10"-->
            <!--                      stroke-linecap="square"-->
            <!--                    />-->
            <!--                    <path-->
            <!--                      d="M10.6667 20C15.8214 20 20 15.8213 20 10.6666C20 5.51199 15.8214 1.33331 10.6667 1.33331C5.51205 1.33331 1.33337 5.51199 1.33337 10.6666C1.33337 15.8213 5.51205 20 10.6667 20Z"-->
            <!--                      stroke="#8EABB8"-->
            <!--                      stroke-width="1.5"-->
            <!--                      stroke-miterlimit="10"-->
            <!--                      stroke-linecap="square"-->
            <!--                    />-->
            <!--                    <path-->
            <!--                      d="M6.00006 10.6666C6.00006 9.42896 6.49173 8.24197 7.3669 7.3668C8.24207 6.49163 9.42905 5.99997 10.6667 5.99997"-->
            <!--                      stroke="#8EABB8"-->
            <!--                      stroke-width="1.5"-->
            <!--                      stroke-miterlimit="10"-->
            <!--                    />-->
            <!--                  </svg>-->
            <!--                </div>-->
            <!--                <div class="text">Counterparties</div>-->
            <!--              </div>-->
            <!--            </contingent-nav-item>-->
          </contingent-nav-content>
          <contingent-nav-footer [matMenuTriggerFor]="menu">
            <div class="dropdown-container account-container" tabindex="0" data-cy="nav-item">
              <div class="account-card">
                <div class="section">
                  <div class="title">{{ tenant }}</div>
                  <div class="description">{{ userName }}</div>
                </div>
                <div class="icon">
                  <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7.5 6L14 1" stroke="#BED7E1" stroke-width="2" stroke-miterlimit="10" />
                  </svg>
                </div>
              </div>
            </div>
          </contingent-nav-footer>
        </contingent-nav>
        <contingent-feedback-button class="feedback-button"></contingent-feedback-button>
      </div>

      <div class="contingent-app">
        <div class="contingent-app-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <mat-menu #menu="matMenu">
      <button (click)="logOut()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        {{ 'SIGN_OUT' | translate }}
      </button>
    </mat-menu>
  </div>
</div>

<ng-template #modal>
  <contingent-search-card data-cy="search-card">
    <contingent-ui-search-card-input-container>
      <contingent-ui-search-card-icon>
        <svg xmlns="http://www.w3.org/2000/svg" width="17.55" height="17.601" viewBox="0 0 17.55 17.601">
          <g id="search" transform="translate(0.5 0.5)" opacity="0.6">
            <g id="Group_1899" data-name="Group 1899">
              <path
                id="Path_3518"
                data-name="Path 3518"
                d="M16.449,15.474l-4.808-4.808a6.564,6.564,0,1,0-.975.975l4.808,4.808a.345.345,0,0,0,.488,0l.487-.488A.345.345,0,0,0,16.449,15.474Zm-9.9-3.751a5.172,5.172,0,1,1,5.172-5.172A5.177,5.177,0,0,1,6.551,11.723Z"
                transform="translate(0 0)"
                fill="#353d4a"
                stroke="#353d4a"
                stroke-width="1"
              />
            </g>
          </g>
        </svg>
      </contingent-ui-search-card-icon>
      <contingent-ui-search-card-input>
        <input
          type="text"
          data-cy="search-card-input"
          placeholder="Search third parties..."
          (keydown)="onSearch($event)"
        />
      </contingent-ui-search-card-input>
    </contingent-ui-search-card-input-container>
    <contingent-ui-search-card-results>
      <contingent-ui-search-card-result
        data-cy="search-card-result"
        *ngFor="let result of searchResults"
        (click)="onOpen(result)"
      >
        <h4 class="title">
          <span class="flag-icon flag-icon-{{ result.jurisdictionCode.toLowerCase() }}"></span>{{ result.entityName }}
        </h4>
        <div class="tags">
          <div class="tag"><span class="highlight">Registration No</span>{{ result.registrationId }}</div>
          <div class="tag"><span class="highlight">Status</span>{{ result.assessmentStatus.overallStatus }}</div>
          <div class="tag"><span class="highlight">Assessment Type</span>{{ result.assessmentType }} report</div>
        </div>
      </contingent-ui-search-card-result>
    </contingent-ui-search-card-results>
  </contingent-search-card>
</ng-template>
