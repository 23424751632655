import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BusinessIdentity,
  BusinessOwnership,
  CountryCode,
  EntityOwnershipAndControlStatement,
  Interest,
  LegacyScreening,
  PersonOwnershipAndControlStatement,
} from '@contingenton/types';
import { GraphQLService } from '@workbench-root/core/services/apis/graphQL.service';
import { EvidenceResponse } from '@workbench-root/types';
import { AddBusinessCommand } from '@workbench-root/types/business';
import { FinancialSummaryResponse } from '@workbench-root/types/financial';
import {
  AppointOfficerRequestBody,
  OfficersQueryResponse,
  UpdateOfficerRequestBody,
} from '@workbench-root/types/officers';
import { OwnershipMode } from '@workbench-root/types/ownership';
import { SearchResponse } from '@workbench-root/types/search';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ADD_BUSINESS,
  APPOINT_OFFICER,
  EVIDENCE,
  EXTRACT_SCREENING_EVENTS,
  FINANCIAL_SUMMARY,
  GET_MATCHING_SCREENED_ENTITIES,
  LEGACY_SCREENING,
  OFFICERS,
  SCREENING,
  SCREENING_EVENTS_COUNT,
  SEARCH,
  TERMINATE_OFFICER,
  UPDATE_OFFICER,
} from '../graphql-queries';

export interface OwnershipAndControlInterest {
  ownershipAndControlStatementId: string;
  interest: Interest;
}

const GET_BUSINESS_IDENTITY = gql`
  query singleBusiness($id: String!) {
    business(id: $id) {
      id
      businessIdentity {
        name
        transliteratedEntityName
      }
      evidence {
        category
      }
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class DiscoveredEntitiesApiService {
  constructor(private httpClient: HttpClient, private apollo: Apollo, private graphqlClient: GraphQLService) {}

  private static getOwnershipModeUriFragment(ownershipMode: OwnershipMode) {
    return ownershipMode === OwnershipMode.BeneficialOwnersRegister ? 'beneficial-owners' : 'company-register';
  }

  addBusiness(command: AddBusinessCommand) {
    const variables = {
      input: command,
    };
    return this.graphqlClient.execute(ADD_BUSINESS, variables);
  }

  getIdentity(id): Observable<BusinessIdentity> {
    this.apollo
      .watchQuery<any>({
        query: GET_BUSINESS_IDENTITY,
        variables: {
          id,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        console.log(data);
      });

    return this.httpClient.get<BusinessIdentity>(`${environment.workbenchApiUri}discovered-entities/${id}/identity`);
  }

  getOfficers(id): Promise<OfficersQueryResponse> {
    const variables = {
      id,
    };
    return this.graphqlClient.execute(OFFICERS, variables);
  }

  getEvidence(id): Promise<EvidenceResponse> {
    const variables = {
      id,
    };
    return this.graphqlClient.execute(EVIDENCE, variables);
  }

  addOfficer(id: string, officer: AppointOfficerRequestBody): Promise<any> {
    const variables = {
      id,
      officer,
    };
    return this.graphqlClient.execute(APPOINT_OFFICER, variables);
  }

  updateOfficer(id: string, officerId: string, officer: UpdateOfficerRequestBody): Promise<any> {
    const variables = {
      id,
      officer,
    };
    return this.graphqlClient.execute(UPDATE_OFFICER, variables);
  }

  deleteOfficer(companyId: string, officerId: string): Promise<any> {
    const variables = {
      officerId,
      businessId: companyId,
    };
    return this.graphqlClient.execute(TERMINATE_OFFICER, variables);
  }

  getBeneficialOwnership(id): Observable<BusinessOwnership> {
    return this.httpClient.get<BusinessOwnership>(
      `${environment.workbenchApiUri}discovered-entities/${id}/beneficial-ownership`,
    );
  }

  getFinancialSummary(id: string): Promise<FinancialSummaryResponse> {
    const variables = {
      id,
    };
    return this.graphqlClient.execute(FINANCIAL_SUMMARY, variables);
  }

  getLegacyScreening(id): Promise<{ legacyScreening: LegacyScreening }> {
    const variables = {
      businessId: id,
    };
    return this.graphqlClient.execute(LEGACY_SCREENING, variables);
  }

  getScreening(id): Promise<{ screening: any }> {
    const variables = {
      businessId: id,
    };
    return this.graphqlClient.execute(SCREENING, variables);
  }

  getScreeningEventsCount(id): Promise<{ screening: any }> {
    const variables = {
      businessId: id,
    };
    return this.graphqlClient.execute(SCREENING_EVENTS_COUNT, variables);
  }

  search(query: string, jurisdiction = ''): Promise<SearchResponse> {
    const variables = {
      query,
      jurisdiction,
    };
    return this.graphqlClient.execute(SEARCH, variables);
  }

  addPersonStatement(
    id: string,
    ownershipMode: OwnershipMode,
    personOwnershipAndControlStatement: PersonOwnershipAndControlStatement,
  ) {
    const mode = DiscoveredEntitiesApiService.getOwnershipModeUriFragment(ownershipMode);
    const url = `${environment.workbenchApiUri}discovered-entities/${id}/${mode}/person-statements`;
    return this.httpClient.post<PersonOwnershipAndControlStatement>(url, personOwnershipAndControlStatement);
  }

  addEntityStatement(
    id: string,
    ownershipMode: OwnershipMode,
    entityOwnershipAndControlStatement: EntityOwnershipAndControlStatement,
  ) {
    const mode = DiscoveredEntitiesApiService.getOwnershipModeUriFragment(ownershipMode);
    const url = `${environment.workbenchApiUri}discovered-entities/${id}/${mode}/entity-statements/`;
    return this.httpClient.post<EntityOwnershipAndControlStatement>(url, entityOwnershipAndControlStatement);
  }

  // DELETE DOES NOT WORK HERE!!
  replaceOwnershipInterest(id: string, ownershipMode: OwnershipMode, ownershipAndControlStatementId: string) {
    const mode = DiscoveredEntitiesApiService.getOwnershipModeUriFragment(ownershipMode);
    const url = `${environment.workbenchApiUri}discovered-entities/${id}/${mode}/ownership-statements`;
    return this.httpClient.put<OwnershipAndControlInterest>(url, {
      ownershipAndControlStatementId,
    });
  }

  removeInterests(
    id: string,
    ownershipMode: OwnershipMode,
    ownershipAndControlStatementId: string,
    interests: Interest[],
  ) {
    const mode = DiscoveredEntitiesApiService.getOwnershipModeUriFragment(ownershipMode);
    const url = `${environment.workbenchApiUri}discovered-entities/${id}/${mode}/remove-interests`;
    return this.httpClient.put<OwnershipAndControlInterest>(url, {
      ownershipAndControlStatementId,
      interests,
    });
  }

  addInterests(data: { companyId: string; statementId: string; interests: Interest[]; mode: OwnershipMode }) {
    const { mode, companyId, interests, statementId } = data;
    const ownershipMode = DiscoveredEntitiesApiService.getOwnershipModeUriFragment(mode);
    const postData = {
      mode: ownershipMode,
      statementId,
      interests,
    };
    const url = `${environment.workbenchApiUri}discovered-entities/${companyId}/add-interests`;
    return this.httpClient.post<OwnershipAndControlInterest>(url, postData);
  }
}
