import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from './user.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  private user: any;

  constructor(private afAuth: AngularFireAuth) {}

  getCurrentUser(): User | null {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      return JSON.parse(userJson);
    } else {
      return null;
    }
  }

  update(user: any) {
    this.user = user;
  }
}
