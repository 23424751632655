<div [formGroup]="form">
  <div>
    <div class="interest-item">
      <div class="form-field-container">
        <div>
          <label>Interest type</label>
        </div>
        <mat-form-field appearance="fill">
          <mat-select formControlName="interestType" placeholder="Select interest type">
            <mat-option *ngFor="let type of interestTypes" [value]="type.value">
              {{ type.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="check-exact-shareholding">
        <mat-checkbox [checked]="isExact" formControlName="isExact">Is exact shareholding</mat-checkbox>
      </div>
      <div class="form-field-container">
        <div *ngIf="isExact" class="exact">
          <label>Exact percentage interest (%)</label>
          <mat-form-field class="exact-shareholding" appearance="fill">
            <input type="number" formControlName="exactPercentage" matInput placeholder="25%" />
          </mat-form-field>
        </div>
        <div *ngIf="!isExact">
          <div class="non-exact">
            <div class="minimum-interest">
              <label>Minimum percentage interest (%)</label>
              <mat-form-field class="min-shareholding" appearance="fill">
                <input type="number" formControlName="minPercentage" matInput placeholder="25%" />
              </mat-form-field>
            </div>
            <div class="space"><span>To</span></div>
            <div class="max-interest">
              <label>Maximum percentage interest (%)</label>
              <mat-form-field class="max-shareholding" appearance="fill">
                <input type="number" formControlName="maxPercentage" matInput placeholder="100%" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
