<mat-nav-list appAccordion class="navigation">
  <mat-list-item appAccordionLink *ngFor="let menuitem of menuService.getAll()" group="{{ menuitem.state }}">
    <a
      class="relative btn-menu"
      [routerLink]="'/' + menuitem.state"
      *ngIf="menuitem.type === 'link'"
      [class.disabled]="menuitem.disabled"
      routerLinkActive="active-link"
    >
      <img src="assets/images/nucleo/{{ menuitem.icon }}.svg" />
      <div class="menu-text">
        <span class="menu-title">{{ menuitem.name | translate }}</span>
        <span class="menu-subtitle" *ngIf="menuitem.subTitle">{{ menuitem.subTitle | translate }}</span>
      </div>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ menuitem.badge.type }}" *ngIf="menuitem.badge && menuitem.badge.value">{{
        menuitem.badge.value
      }}</span>
    </a>
    <a appAccordionToggle class="relative" href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'">
      <img src="assets/images/nucleo/{{ menuitem.icon }}.svg" />
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ menuitem.badge.type }}" *ngIf="menuitem.badge && menuitem.badge.value">{{
        menuitem.badge.value
      }}</span>
    </a>
    <a
      appAccordionToggle
      class="relative"
      href="{{ menuitem.state }}"
      target="_blank"
      *ngIf="menuitem.type === 'extTabLink'"
    >
      <img src="assets/images/nucleo/{{ menuitem.icon }}.svg" />
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ menuitem.badge.type }}" *ngIf="menuitem.badge && menuitem.badge.value">{{
        menuitem.badge.value
      }}</span>
    </a>
    <a appAccordionToggle class="relative" href="javascript:;" *ngIf="menuitem.type === 'sub'">
      <img src="assets/images/nucleo/{{ menuitem.icon }}.svg" />
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ menuitem.badge.type }}" *ngIf="menuitem.badge && menuitem.badge.value">{{
        menuitem.badge.value
      }}</span>
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </a>
    <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
      <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
        <a [routerLink]="['/', menuitem.state, childitem.state]" class="relative">{{ childitem.name | translate }}</a>
      </mat-list-item>
    </mat-nav-list>
  </mat-list-item>
</mat-nav-list>
