export const environment = {
  production: false,
  workbenchApiUri: 'https://europe-west2-qa1-vpc-c6072593.cloudfunctions.net/workbench/',
  workbenchGraphQLApi: 'https://api.qa1.contingenton.com/workbench/graphql',
  firebase: {
    apiKey: 'AIzaSyAig6Y5PKqWLCnx8Cq9S7mgkQyw0JtXBNo',
    authDomain: 'qa1-internal-identity-a4b91a.firebaseapp.com',
    databaseURL: 'https://qa1-internal-identity-a4b91a.firebaseio.com',
    projectId: 'qa1-internal-identity-a4b91a',
    storageBucket: 'qa1-internal-identity-a4b91a.appspot.com',
    messagingSenderId: '258018087021',
    appId: '1:258018087021:web:7bde04476be5833d16af64',
    measurementId: 'G-BTH7L7JE67',
  },
  workbenchAPI: 'https://api.qa1.contingenton.com/workbench/',
  appReportPreviewUri: 'https://app.qa1.contingenton.com/businesses/{COMPANY_ID}/overview?preview=true',
  waitlystPublishableKey: 'pk_test_6057c7a0-9eeb-4cfb-8bb4-9e5085ef307d',
  telemetryUrl: 'https://telemetry.qa1.contingenton.com/v1/traces',
};
