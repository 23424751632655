import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  CompanyOfficers,
  OfficersQueryResponse,
  TerminateOfficerAppointmentRequestBody,
} from '../../../types/officers';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CompanyOfficer, DiscoveredCompanyOfficer } from '@contingenton/types';

const TERMINATE_OFFICER_APPOINTMENT = gql`
  mutation TerminateOfficerAppointment(
    $businessId: String!
    $officer: OfficerIdentityInput!
    $terminatedOn: DateTime!
    $evidence: EvidenceInputType!
  ) {
    terminateOfficerAppointment(
      businessId: $businessId
      officer: $officer
      terminatedOn: $terminatedOn
      evidence: $evidence
    ) {
      name
      isActive
    }
  }
`;

const DELETE_OFFICER = gql`
  mutation DeleteOfficer($businessId: String!, $officer: OfficerIdentityInput!) {
    deleteOfficer(businessId: $businessId, officer: $officer) {
      name
      role
      appointedOn
      occupation
      nationalityCountryCodes
      dateOfBirth {
        month
        year
      }
      isActive
    }
  }
`;

export const GET_OFFICERS = gql`
  query Officers($businessId: String!) {
    officers(businessId: $businessId) {
      officers {
        id
        name
        role
        dateOfBirth {
          month
          year
        }
        nationalityCountryCodes
        address {
          countryCode
          city
        }
        isActive
      }
    }
  }
`;

@Injectable()
export class OfficersApi {
  constructor(private apollo: Apollo) {}

  deleteOfficer(businessId: string, officer: DiscoveredCompanyOfficer): Observable<DiscoveredCompanyOfficer[]> {
    if (officer.dateOfBirth && officer.dateOfBirth.month && officer.dateOfBirth.year) {
      console.log(
        `Date of birth for deleted officer; month ${officer.dateOfBirth.month}, year ${officer.dateOfBirth.year}`,
      );
      return this.apollo
        .mutate<DiscoveredCompanyOfficer[]>({
          mutation: DELETE_OFFICER,
          variables: {
            businessId,
            officer: {
              id: officer.id,
              name: officer.name,
              role: officer.role,
              dateOfBirth: {
                month: officer.dateOfBirth.month,
                year: officer.dateOfBirth.year,
              },
            },
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
          refetchQueries: ['uncommittedEvents'],
        })
        .pipe(map((response) => response.data));
    }

    return this.apollo
      .mutate<DiscoveredCompanyOfficer[]>({
        mutation: DELETE_OFFICER,
        variables: {
          businessId,
          officer: {
            id: officer.id,
            name: officer.name,
            role: officer.role,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        refetchQueries: ['uncommittedEvents'],
      })
      .pipe(map((response) => response.data));
  }

  getOfficers(businessId: string): Observable<CompanyOfficers> {
    return this.apollo
      .watchQuery<OfficersQueryResponse>({
        query: GET_OFFICERS,
        variables: {
          businessId,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data.officers));
  }

  terminateOfficerAppointment(
    businessId: string,
    officer: TerminateOfficerAppointmentRequestBody,
  ): Observable<OfficersQueryResponse> {
    const dateOfBirth = moment(officer.dateOfBirth);

    return this.apollo
      .mutate<OfficersQueryResponse>({
        mutation: TERMINATE_OFFICER_APPOINTMENT,
        variables: {
          businessId,
          officer: {
            id: officer.id,
            name: officer.name,
            role: officer.role,
            dateOfBirth: {
              month: dateOfBirth.month(),
              year: dateOfBirth.year(),
            },
          },
          terminatedOn: officer.terminatedOn,
          evidence: officer.evidence,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        refetchQueries: ['uncommittedEvents'],
      })
      .pipe(map((response) => response.data));
  }
}
