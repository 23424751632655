<mat-tab-group [selectedIndex]="1" mat-stretch-tabs>
  <mat-tab>
    <ng-template matTabLabel>Reports</ng-template>
    <div class="scroll">
      <mat-nav-list>
        <mat-divider></mat-divider>
        <h3 mat-subheader class="text-uppercase font-weight-bold">Requested</h3>
        <mat-list-item>
          <a matLine href="javascript:;">AOS2 Enterprise</a>
          <span class="mat-text-muted mr-1">{{ today | date: 'dd MMM' }}</span>
        </mat-list-item>
        <mat-list-item>
          <a matLine href="javascript:;">Sky Mobile</a>
          <span class="mat-text-muted mr-1">{{ today | date: 'dd MMM' }}</span>
        </mat-list-item>
        <mat-list-item>
          <a matLine href="javascript:;">Telehouse Incorporation</a>
          <span class="mat-text-muted mr-1">{{ today | date: 'dd MMM' }}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader class="text-uppercase font-weight-bold">Updated</h3>
        <mat-list-item>
          <a matLine href="javascript:;">Chinacast Education</a>
          <span class="mat-text-muted mr-1">{{ today | date: 'dd MMM' }}</span>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>Notifications</ng-template>
    <div class="scroll">
      <mat-nav-list>
        <mat-list-item>
          <mat-icon mat-list-avatar class="mat-text-warn">people</mat-icon>
          <h4 matLine>Beneficial ownership change</h4>
          <p matLine>SkyMobile has a new owner</p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-avatar class="mat-text-primary">mail_outline</mat-icon>
          <h4 matLine>Headquarters address change</h4>
          <p matLine>Telehouse international address changed on</p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-avatar class="mat-text-accent">info</mat-icon>
          <h4 matLine>Change in percentage ownership</h4>
          <p matLine>Russell now owns 45% of AOS2</p>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-tab>
</mat-tab-group>
