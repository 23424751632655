<div [formGroup]="form">
  <div class="form-header">
    <mat-icon mat-card-avatar>bookmarks</mat-icon>
    <h3>Evidence</h3>
  </div>
  <div fxLayout="row" fxFill fxLayoutGap="20px">
    <mat-form-field fxFlex="45" appearance="fill">
      <mat-label>Evidence source</mat-label>
      <input
        type="text"
        formControlName="source"
        matInput
        placeholder="Source - Website section, PDF, application, etc"
        required
      />
      <mat-error *ngIf="source.invalid">Please enter the evidence source</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="55" appearance="fill">
      <mat-label>Url</mat-label>
      <input type="url" formControlName="url" matInput placeholder="Url of document" />
      <mat-error *ngIf="URLInvalid">Please enter a valid URL. e.g https://companieshouse.co.uk</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxFill fxLayoutGap="20px" style="padding: 15px 0">
    <mat-form-field fxFlex="45" appearance="fill">
      <mat-label>Publisher</mat-label>
      <input
        type="text"
        formControlName="publisherName"
        matInput
        placeholder="Companies House, Company website, etc"
        required
      />
      <mat-error *ngIf="publisherName.invalid">Please enter the publisher name</mat-error>
    </mat-form-field>
    <mat-form-field class="exact-shareholding" fxFlex="55" appearance="fill">
      <mat-label>Publication date</mat-label>
      <input
        type="date"
        formControlName="publicationDate"
        matInput
        placeholder="Date when info was published"
        required
      />
      <mat-error *ngIf="publicationDate.invalid">Please select the publication date</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxFill>
    <mat-form-field fxFill appearance="fill">
      <mat-label>Snippet</mat-label>
      <input type="text" formControlName="snippet" matInput placeholder="A snippet of text from the website" />
    </mat-form-field>
  </div>
</div>
