<div class="summary">
  <div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <mat-card>
    <mat-card-title class="summary-title"
      >Summary Information
      <button mat-raised-button color="primary" class="detail__approve" (click)="done()">OK</button>
    </mat-card-title>
    <mat-card-content>
      <ngx-datatable
        class="material"
        [rows]="summaries"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [limit]="5"
        [rowHeight]="'auto'"
      >
        <ngx-datatable-column name="Jusisdiction" prop="jurisdiction" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span [appCountryFlag]="row?.jurisdiction"></span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Name" prop="entityName" [flexGrow]="2">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span>{{ row?.entityName }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="ID" prop="entityId" [flexGrow]="2">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span>{{ row?.entityId }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Address" prop="legalAddress" [flexGrow]="4">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span>{{ row?.legalAddress.firstLineOfAddress }}, </span>
            <span>{{ row?.legalAddress.city }}, </span>
            <span>{{ row?.legalAddress.postalCode }}, </span>
            <span>{{ row?.legalAddress.regionCode }}, </span>
            <span [appCountryFlag]="row?.countryCode"></span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card-content>
  </mat-card>
</div>
