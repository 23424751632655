<div mat-dialog-container>
  <mat-toolbar *ngIf="style == 1" color="primary" class="mat-elevation-z2">
    <mat-card-header>{{ title }}</mat-card-header>
  </mat-toolbar>
  <div class="message">
    <p matTooltip="{{ message }}" *ngFor="let message of messages">{{ message | ellipsis: textLength }}</p>
  </div>
  <div class="info" *ngIf="information !== ''">
    <p>{{ information }}</p>
  </div>
  <div class="dialog-button-group">
    <div *ngIf="button == 0">
      <button class="dialog-select-button" mat-raised-button color="accent" (click)="onOk()" tabindex="-1">
        {{ 'OK' | translate }}
      </button>
    </div>
    <div class="dialog-select-button-group" *ngIf="button == 1">
      <button class="dialog-select-button" mat-raised-button color="accent" (click)="onOk()" tabindex="-1">
        {{ 'OK' | translate }}
      </button>
      <button class="dialog-select-button" mat-raised-button color="warn" (click)="onCancel()" tabindex="-1">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
    <div class="dialog-select-button-group" *ngIf="button == 2">
      <button class="dialog-select-button" mat-raised-button color="accent" (click)="onYes()" tabindex="-1">
        {{ 'YES' | translate }}
      </button>
      <button class="dialog-select-button" mat-raised-button color="warn" (click)="onNo()" tabindex="-1">
        {{ 'NO' | translate }}
      </button>
    </div>
    <div *ngIf="button == 3">
      <button class="dialog-select-button" mat-raised-button color="accent" (click)="onAccept()" tabindex="-1">
        {{ 'ACCEPT' | translate }}
      </button>
      <button class="dialog-select-button" mat-raised-button color="warn" (click)="onReject()" tabindex="-1">
        {{ 'REJECT' | translate }}
      </button>
    </div>
  </div>
</div>
