export enum UpdateSection {
  ContactDetails = 'contact',
  Description = 'description',
  IndustryClassification = 'industry',
  Jurisdiction = 'jurisdiction',
  Address = 'address',
  PreviousNames = 'previousNames',
  LegalEntity = 'legalEntity',
  HeadQuarterAddress = 'headquarterAddress',
  TransliteratedName = 'transliteratedName',
  CompanyName = 'companyName',
  TradeNames = 'tradeNames',
}

export enum AddressTypes {
  HeadquartersAddress = 'headquartersAddress',
  LegalAddress = 'legalAddress',
}

export enum NameUpdateTypes {
  Name = 'name',
  TransliteratedName = 'transliteratedName',
}
