<mat-toolbar class="main-header" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <a [routerLink]="'/'">
      <div class="branding">
        <img class="logo" src="/assets/images/logo.svg" width="109" height="25px" />
      </div>
    </a>
    <a
      *ngFor="let menuitem of menuService.getAll()"
      mat-button
      class="menu-header-item"
      [routerLink]="'/' + menuitem.state"
      [routerLinkActiveOptions]="{ exact: menuitem.state === '/' }"
      routerLinkActive="active-link"
      #rla="routerLinkActive"
    >
      <img *ngIf="rla.isActive" src="assets/images/nucleo/{{ menuitem.icon }}-active.svg" />
      <img *ngIf="!rla.isActive" src="assets/images/nucleo/{{ menuitem.icon }}-white.svg" />
      <span class="title">{{ menuitem.name | translate }}</span>
    </a>
  </div>
  <div style="width: 100%" fxLayout="row" fxLayoutAlign="flex-end center">
    <button (click)="fullScreenToggle()" mat-icon-button>
      <mat-icon class="white">fullscreen</mat-icon>
    </button>

    <button [matMenuTriggerFor]="user" mat-icon-button class="ml-xs">
      <mat-icon *ngIf="!image">person</mat-icon>
      <img
        *ngIf="image"
        style="width: 44px; height: 44px; border-radius: 24px"
        class="card-img-top image"
        [src]="image"
        alt="User image"
      />
    </button>
    <mat-menu #user="matMenu" x-position="before">
      <button (click)="authService.logOut()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        {{ 'SIGN_OUT' | translate }}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
