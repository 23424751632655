import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'contingent-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContingentNavComponent {
  @Input() opened;

  onToggle() {
    this.opened = !this.opened;
  }
}
