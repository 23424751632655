import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { UserService } from '../services/core/user.service';
import { AuthService } from '../services/core/auth.service';

@Injectable()
export class ResearchWebBearerTokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private auth: AuthService) {}

  generateHandle(request, next): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('Response:', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const data = {
          text: error.status + ' : ' + (error ? error.statusText : ''),
          type: 'error',
        };
        return throwError(error);
      }),
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.userService.getCurrentUser() ? this.userService.getCurrentUser().idToken : null;

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    if (this.auth.checkIfExpired()) {
      return this.auth.getIdToken().pipe(
        switchMap((res: any) => {
          if (res !== false) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + res) });
          }
          return this.generateHandle(request, next);
        }),
      );
    }
    return this.generateHandle(request, next);
  }
}
