import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-ng-simple-dialog',
  templateUrl: './ng-simple-dialog.component.html',
  styleUrls: ['./ng-simple-dialog.component.scss'],
})
export class NgSimpleDialogComponent {
  style: number;
  title: string;
  messages: Array<string>;
  information: string;
  button: number;
  allowOutsideClick: boolean;
  textLength: number;

  constructor(
    public dialogRef: MatDialogRef<NgSimpleDialogComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.style = data.style || 0;
    this.title = data.title;
    this.messages = data.messages;
    this.information = data.information;
    this.button = data.button;
    this.textLength = data.textLength || 100;
    this.dialogRef.disableClose = !data.allowOutsideClick || false;
  }
  onOk() {
    this.dialogRef.close({ result: 'ok' });
  }
  onCancel() {
    this.dialogRef.close({ result: 'cancel' });
  }
  onYes() {
    this.dialogRef.close({ result: 'yes' });
  }
  onNo() {
    this.dialogRef.close({ result: 'no' });
  }
  onAccept() {
    this.dialogRef.close({ result: 'accept' });
  }
  onReject() {
    this.dialogRef.close({ result: 'reject' });
  }
}
