import { Injectable } from '@angular/core';

export interface BadgeItem {
  type?: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  subTitle?: string;
  disabled?: boolean;
  badge?: BadgeItem;
  children?: ChildrenItems[];
}

const MENU_ITEMS = [
  {
    state: '/',
    name: 'Requests',
    type: 'link',
    icon: 'bank-statement',
  },
  {
    state: 'unknown-business-requests',
    name: 'Unknown business requests',
    type: 'link',
    icon: 'folder-question',
  },
  {
    state: 'alerts',
    name: 'alerts',
    type: 'link',
    icon: 'alerts',
  },
  {
    state: 'subscriptions',
    name: 'Subscriptions',
    type: 'link',
    icon: 'contacts',
  },
];

const REPORT_DETAILS_MENU_ITEMS = [
  {
    state: `/requests/details/{id}/identity`,
    routePath: `identity`,
    name: 'IDENTITY',
    type: 'link',
    icon: 'overview-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/contact-info`,
    routePath: `contact-info`,
    name: 'CONTACT INFO',
    type: 'link',
    icon: 'contact-info-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/officers`,
    routePath: `officers`,
    name: 'OFFICERS',
    type: 'link',
    icon: 'officers-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/ownership`,
    routePath: `ownership`,
    name: 'OWNERSHIP',
    type: 'link',
    icon: 'ownership-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/financial`,
    routePath: `financial`,
    name: 'FINANCIAL',
    type: 'link',
    icon: 'financial-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/screening`,
    routePath: `screening`,
    name: 'SCREENING',
    type: 'link',
    icon: 'screening-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/evidence`,
    routePath: `evidence`,
    name: 'EVIDENCE',
    type: 'link',
    icon: 'evidence-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/news`,
    routePath: `news`,
    name: 'NEWS',
    type: 'link',
    icon: 'news-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/changes`,
    routePath: `changes`,
    name: 'CHANGES',
    type: 'link',
    icon: 'changes-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/insights`,
    routePath: `insights`,
    name: 'INSIGHTS',
    type: 'link',
    icon: 'insights-icon',
    disabled: false,
  },
  {
    state: `/requests/details/{id}/comments`,
    routePath: `comments`,
    name: 'COMMENTS',
    type: 'link',
    icon: 'comments-icon',
    disabled: false,
  },
];

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  getAll(): Menu[] {
    return MENU_ITEMS;
  }

  getMenuDetailsItems(): Menu[] {
    return REPORT_DETAILS_MENU_ITEMS;
  }

  add(menu) {
    MENU_ITEMS.push(menu);
  }
}
