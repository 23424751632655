import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ContingentSearchIcon,
  ContingentSearchInput,
  ContingentSearchInputContainer,
  ContingentSearchResult,
  ContingentSearchResults,
} from './search-card';
import { SearchCardComponent } from './search-card.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ContingentSearchIcon,
    ContingentSearchResults,
    ContingentSearchResult,
    ContingentSearchInputContainer,
    ContingentSearchInput,
    SearchCardComponent,
  ],
  exports: [
    ContingentSearchIcon,
    ContingentSearchResults,
    ContingentSearchResult,
    ContingentSearchInputContainer,
    ContingentSearchInput,
    SearchCardComponent,
  ],
})
export class SharedUiSearchCardModule {}
