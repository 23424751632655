import { MatSnackBar } from '@angular/material/snack-bar';

declare interface ErrorType {
  response: {
    errors: { message: string }[];
    data: unknown;
    status: number;
  };
  request: { query: unknown; variables: unknown };
}

export class ErrorHandler {
  constructor(public toast: MatSnackBar) {}

  onError(error: ErrorType, callback: () => void): void {
    if (error && error.response) {
      const { errors } = error.response;
      const message = errors[0].message;
      const toastRef = this.toast.open(message, 'Retry', { duration: 5000 });
      toastRef.onAction().subscribe(() => {
        callback();
      });
    } else {
      const toastRef = this.toast.open('An error occured while trying to complete the operation', 'Retry', {
        duration: 5000,
      });
      toastRef.onAction().subscribe(() => {
        callback();
      });
    }
    console.error(error);
  }
}
