<div class="ui-contingent-card" [class.opened]="opened">
  <mat-drawer-container [hasBackdrop]="false">
    <mat-drawer-content>
      <div class="group">
        <ng-content select="contingent-nav-header"></ng-content>
        <ng-content select="contingent-nav-content"></ng-content>
      </div>
      <ng-content select="contingent-nav-footer"></ng-content>
    </mat-drawer-content>
    <!-- Side nav -->
    <mat-drawer mode="side" position="end" [opened]="opened">
      <ng-content select="contingent-sidenav"></ng-content>
    </mat-drawer>
  </mat-drawer-container>
</div>
