import { gql } from 'graphql-request';

const SCREENING_HIT_FRAGMENT = `
fragment ScreeningHit on ScreeningResultType {
    id
    date
    source
    snippet
    content
  }
`;

const LEGACY_SCREENING_BODY = `
 blacklisted {
        ...ScreeningHit
      }
      litigation {
        ...ScreeningHit
      }
      adverseMedia {
        ...ScreeningHit
      }
      corporateActions {
        ...ScreeningHit
      }
      sanctions {
        ...ScreeningHit
      }
      PEPs {
        ...ScreeningHit
      }
      screeningIds
      screeningDate
`;

export const ADD_SCREENING_RESULT = gql`
  mutation AddScreeningResults($businessId: String!, $result: ScreeningResultsInput!) {
    addScreeningResults(businessId: $businessId, result: $result) {
      ${LEGACY_SCREENING_BODY}
    }
  }

  ${SCREENING_HIT_FRAGMENT}
`;

export const ADD_ADVERSE_MEDIA_EVENT = gql`
  mutation appendAdverseMedia($id: String!, $adverseMedia: AppendAdverseMediaInput!) {
    appendAdverseMedia(id: $id, adverseMedia: $adverseMedia)
  }
`;

export const ADD_SANCTION_EVENT = gql`
  mutation appendSanction($id: String!, $sanction: AppendSanctionInput!) {
    appendSanction(id: $id, sanction: $sanction)
  }
`;

export const ADD_PEP_EVENT = gql`
  mutation appendPep($id: String!, $pep: AppendPepInput!) {
    appendPep(id: $id, pep: $pep)
  }
`;

export const ADD_SCREENING_EVENTS = gql`
  mutation AddScreeningEvents($businessId: String!, $data: ScreeningEventsInput!) {
    addScreeningEvents(businessId: $businessId, data: $data) {
      types
      title
      summary
      date
      keywordsMatched
      link
    }
  }
`;

export const LEGACY_SCREENING = gql`
  query LegacyScreening($businessId: String!) {
    legacyScreening(businessId: $businessId) {
      ${LEGACY_SCREENING_BODY}
    }
  }

  ${SCREENING_HIT_FRAGMENT}
`;

export const SCREENING_EVENTS_COUNT = gql`
  query Screening($businessId: String!) {
    screening(businessId: $businessId) {
      eventsCount
    }
  }
`;

export const SCREENING = gql`
  query Screening($businessId: String!) {
    screening(businessId: $businessId) {
      complianceStatus {
        lastScreened
        screenedBusinesses {
          id
          name
          source
          context
          type
          aliases
          matchType
          addresses {
            firstLineOfAddress
            additionalLineOfAddress
            city
            countryCode
            regionCode
            premises
            postalCode
          }
          potentialMatches {
            types
            matchType
            link
            title
            summary
            date
            retrievedAt
          }
        }

        screenedPersons {
          id
          name
          source
          context
          type
          aliases
          matchType
          addresses {
            firstLineOfAddress
            additionalLineOfAddress
            city
            countryCode
            regionCode
            premises
            postalCode
          }
          potentialMatches {
            types
            matchType
            link
            title
            summary
            date
            retrievedAt
            classes
          }
        }
      }
    }
  }
`;

export const SET_DEFAULT_SCREENING_EVENTS = gql`
  mutation SetDefaultScreeningEvents($id: String!) {
    setDefaultScreeningEvents(businessId: $id) {
      ${LEGACY_SCREENING_BODY}
    }
  }

  ${SCREENING_HIT_FRAGMENT}
`;

export const EXTRACT_SCREENING_EVENTS = gql`
  mutation extractScreeningEventsForMatchingEntity(
    $businessId: String!
    $matchingEntity: MatchingScreeningEntityInput!
  ) {
    extractScreeningEventsForMatchingEntity(businessId: $businessId, matchingEntity: $matchingEntity) {
      screeningDate
    }
  }
`;

export const GET_MATCHING_SCREENED_ENTITIES = gql`
  query getMatchingScreenedEntities($entity: ScreenedEntityInput!) {
    getMatchingScreenedEntities(entity: $entity) {
      id
      source
      context
      addresses {
        firstLineOfAddress
        additionalLineOfAddress
        city
        countryCode
        regionCode
        premises
        postalCode
      }
      name
      score
    }
  }
`;

// Legacy
export const MATCHING_SCREENED_BUSINESSES = gql`
  query GetMatchingScreenedBusinesses($id: String!) {
    getMatchingCompaniesFromRequest(businessId: $id) {
      id
      faxNumber
      isFinancialregulator
      isDisqualifiedDirector
      isInsolvent
      isAdverseMedia
      addresses {
        address1
        address2
        address3
        city
        county
        country {
          name
        }
        postcode
      }
      linkedPersons {
        personId
        position
      }
      telephoneNumber
      notes {
        text
        dataSource
      }
      sanctions
      businessName
      isPEP
      isSanctionsCurrent
      isSanctionsPrevious
      linkedBusinesses
      isLawEnforcement
      website
      articles {
        originalURL
        dateCollected
        c6URL
        categories {
          name
        }
        snippet {
          title
          summary
          keywordsMatched
        }
      }
    }
  }
`;

export const GET_SCREENING_EVENTS_FROM_BUSINESS = gql`
  mutation GetScreeningEventsFromBusiness($id: String!, $business: BusinessInputType!) {
    getScreeningEventsFromBusiness(businessId: $id, business: $business) {
      ${LEGACY_SCREENING_BODY}
    }
  }
  ${SCREENING_HIT_FRAGMENT}
`;
