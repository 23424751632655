import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@workbench-root/core/services/core/auth.service';
import { UserService } from '@workbench-root/core/services/core/user.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AssessmentsApiService } from '@workbench-root/core/services/apis/assessments-api.service';
import { AssessmentEntity } from '@workbench-root/types/assessment';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: false }) modal;
  private Router: Subscription;
  private dialogRef: MatDialogRef<any>;
  searchResults: AssessmentEntity[] = [];

  mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  url: string;
  sidePanelOpened;
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr',
  };

  userName;
  tenant;

  @ViewChild('sidemenu')
  sideMenu;
  @ViewChild(PerfectScrollbarDirective)
  directiveScroll: PerfectScrollbarDirective;

  config: PerfectScrollbarConfigInterface = {};

  constructor(
    public dialog: MatDialog,
    private element: ElementRef,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private assessmentService: AssessmentsApiService,
  ) {}

  ngOnInit(): void {
    this.url = this.router.url;

    this.Router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
        this.runOnRouteChange();
      });

    const currentUser = this.userService.getCurrentUser();
    this.userName = currentUser.displayName;
    this.tenant = currentUser?.tid?.split('-')[0] || currentUser.email.split('@')[1];
  }

  ngOnDestroy(): void {
    this.Router.unsubscribe();
  }

  runOnRouteChange(): void {
    this.updatePS();
  }

  isOver(): boolean {
    if (
      this.url === '/apps/messages' ||
      this.url === '/apps/calendar' ||
      this.url === '/apps/media' ||
      this.url === '/maps/leaflet' ||
      this.url === '/taskboard'
    ) {
      return true;
    } else {
      return this.mediaMatcher.matches;
    }
  }

  updatePS(): void {
    if (!this.mediaMatcher.matches && !this.options.compact) {
      setTimeout(() => {
        this.directiveScroll.update();
      }, 350);
    }
  }

  logOut() {
    this.authService.logOut();
  }

  openMatDialog() {
    this.dialogRef = this.dialog.open(this.modal, {
      width: '550px',
    });
  }

  onSearch(e) {
    const value = e.target.value;
    this.assessmentService.getAllAssessments(value).then((data) => {
      this.searchResults = data.assessments.results;
    });
  }

  onOpen(result: AssessmentEntity) {
    const url = `/assessments/${result.assessmentType.toLowerCase()}/${result.id}/`;
    this.router.navigate([url]);
    this.dialogRef.close();
  }
}
