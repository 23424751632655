import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const GET_COMMENTS = gql`
  query comments($threadId: String!) {
    comments(threadId: $threadId) {
      id
      threadId
      threadType
      text
      createdTimestamp
      author {
        id
        name
      }
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation createComment($input: CommentInputType!) {
    createComment(comment: $input) {
      threadId
      threadType
      text
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation updateComment($threadId: String!, $commentId: String!, $input: CommentInputType!) {
    updateComment(threadId: $threadId, commentId: $commentId, comment: $input) {
      id
      threadId
      threadType
      text
      createdTimestamp
      author {
        id
        name
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($threadId: String!, $commentId: String!) {
    deleteComment(threadId: $threadId, commentId: $commentId) {
      id
      threadId
      threadType
      text
      createdTimestamp
      author {
        id
        name
      }
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class CommentsGQLService {
  constructor(private apollo: Apollo) {}

  addComment(threadId: string, threadType: string, addCommentForm: any) {
    const { commentId, ...comment } = addCommentForm;
    const input = {
      threadId: `${threadId}`,
      threadType,
      ...comment,
    };
    console.log(input);
    return this.apollo
      .watchQuery<CommentsResponse>({
        query: ADD_COMMENT,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data));
  }

  deleteComment(threadId: string, commentId: string) {
    return this.apollo.watchQuery<CommentsResponse>({
      query: DELETE_COMMENT,
      variables: {
        threadId,
        commentId,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  getComments(threadId: string): Observable<CommentDto[]> {
    return this.apollo
      .watchQuery<CommentsResponse>({
        query: GET_COMMENTS,
        variables: {
          threadId,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data.comments));
  }

  updateComment(threadId: string, addCommentForm: any) {
    const { id, ...comments } = addCommentForm;

    return this.apollo
      .watchQuery<CommentsResponse>({
        query: UPDATE_COMMENT,
        variables: {
          threadId,
          commentId: id,
          input: {
            threadId,
            ...comments,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data));
  }
}

export interface AuthorDto {
  id: string;
  name: string;
}

export enum ThreadType {
  INSIGHT = 'Insight',
  BUSINESS = 'Business',
}

export interface CommentDto {
  author: AuthorDto;
  id: string;
  threadId: string;
  threadType: ThreadType;
  text: string;
  createdTimestamp: Date;
}

export interface CommentsResponse {
  comments: Array<CommentDto>;
}
