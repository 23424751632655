import { gql } from 'graphql-request';

export const SEARCH = gql`
  query Search($query: String!, $jurisdiction: String!) {
    search(query: $query, jurisdiction: $jurisdiction) {
      id
      legalName
      legalAddress {
        firstLineOfAddress
        additionalLineOfAddress
        premises
        postalCode
        countryCode
        regionCode
      }
      jurisdictionCode
      legalJurisdictionCode
      legalEntityForm
      registrationAuthority {
        registrationAuthorityCode
        entityId
        status
      }
    }
  }
`;
