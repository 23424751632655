import { Component, Input } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import { countries, Jurisdiction } from '@workbench-root/types';

export const AddressFields = {
  city: ['', Validators.required],
  countryCode: ['', Validators.required],
  firstLineOfAddress: ['', Validators.required],
  regionCode: [''],
  postalCode: [''],
  premises: [''],
  additionalLineOfAddress: [''],
};

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent {
  constructor(public controlContainer: ControlContainer) {}

  @Input() submitted: boolean;
  jurisdictions: Jurisdiction[] = countries;

  get formInvalid() {
    return this.controlContainer.invalid;
  }
}
